.auth-page
	.auth-content
		width 95%
		max-width 28rem
		margin-top 15vh

		.loading-component
			margin-top 1rem
			text-align center

		.heading
			$super-bold()
			text-transform uppercase
			text-align center
			margin-bottom 2.5rem
			color $primary-font-pop

		// .heading i
		// 	display table
		// 	font-size 5rem
		// 	color $primary
		// 	margin 0 auto 2.5rem

		.content
			color $secondary-font

			.highlight
				color $primary-font-pop

			a:not(.button)
				color $blue-link

			.bnet-down
				color $red

			.centered
				text-align center

			ol, ul
				margin 0 0 1rem
				line-height 1.35

				li + li
					margin-top 0.35rem

			ol
				list-style decimal

			ul
				list-style: disc

		.auth-button
			margin 2rem auto 0
			width 10rem

	.footer
		position fixed
		left 0
		right 0
		bottom 1.25rem
		color $super-faded-font
		text-align center

		.disclaimer
			margin-bottom 0.15rem

		a
			text-decoration underline

.auth-page.sign-in
	.button-group
		margin-top 3rem

		.button
			width 15rem

			& + .button
				margin-top 0.75rem

		a
			margin-top 2rem !important

.auth-page.character-loader
	.region
		$bold()
		margin-bottom 2rem

.auth-region-picker
	max-width 18rem !important
	padding 0 1.25rem 1.25rem

	.regions
		margin 0 auto

		.button
			width 100%

			& + .button
				margin-top 0.65rem

	.cancel-button
		margin 0.75rem auto 0
		color $super-faded-font

.auth-page.no-wow-permission
	.auth-content
		max-width 37rem

		.method
			margin-top 2rem

			& + .method
				margin-top 3rem

			ol, ul
				list-style-position: inside

			ul
				margin-top 1rem

			.method-label
				display: table
				border-radius $border-radius
				color $darker-light
				padding 0.15rem 0.35rem
				text-transform: uppercase
				font-size 0.85rem
				letter-spacing: 0.5px
				position: relative
				left -5px
				margin-bottom 0.5rem

				&.easy
					background-color: $green

				&.hard
					background-color: $orange
