.image-background-wrapper
	.image-background
		position fixed
		top 0
		bottom 0
		left 0
		right 0
		z-index -2

		img
			position absolute
			opacity 0.07
			z-index -2
