.select-roles
	padding 8vh 2rem 2rem

	&.embedded
		padding 0

	.heading
		text-align center
		width 35rem
		max-width 95%
		margin 0 auto 3rem

		h1
			$super-bold()

		p
			width 25rem
			margin-left auto
			margin-right auto

		.submit-buttons
			margin-top 3rem
			margin-bottom 1rem

		.note
			margin-top 1.5rem
			color $faded-font
			font-size 0.875rem

	.submit-buttons
		*
			margin-right auto
			margin-left auto
			min-width 8.5rem

	.content
		& + .submit-buttons
			margin-top 5rem
			margin-bottom 3rem

	.character-row
		position relative
		display flex
		align-items center
		justify-content space-between
		width 95%
		max-width 30rem
		padding 0.75rem
		padding-left 1rem
		margin 0 auto 0.75rem
		border 1px solid $card-border
		border-radius $border-radius
		border-bottom-left-radius 0
		border-top-left-radius 0
		border-left none
		background $focus-box-linear-gradient-hover
		user-select none

		&:before
			position absolute
			width 4px
			left @width * -1
			top -1px
			bottom -1px
			display block
			content ' '
			z-index 1

		&.roleless
			background $error-box-linear-gradient

		.left
			display flex
			align-items center

		.thumbnail
			border-radius $border-radius
			width 3rem
			height @width
			margin-right 0.85rem

		.details
			flex 1 0 0%

			.name
				$bold()
				font-size 1.15rem

			.secondary
				color $secondary-font

		.role-selection
			display flex

			.role
				position relative
				width 2.75rem
				height @width
				padding 0.25rem
				margin-right 0.15rem
				cursor pointer
				border-radius $border-radius
				border 1px solid transparent

				&:last-child
					margin-right 0

				&:hover
					background $focus-box-linear-gradient-hover-bright

				&.selected
					background $focus-box-linear-gradient-selected
					border-color $primary-pop

				&.disabled
					pointer-events none
					opacity 0.20

				img
					width 100%
					height auto
					opacity $faded-opacity

				.checkmark-circle
					position absolute
					top -7px
					right @top
					width 20px
					height @width
					font-size 0.7rem
					display flex
					justify-content center
					align-items center
					border-radius 50%
					background-color $green - 20%
					color $white
