.roster-settings-content
	.tagging
		margin-top 2.5rem

		.tagging-note
			margin-bottom 0.5rem
			color $secondary-font
			font-size 0.8125rem

		.tag-section
			& + .tag-section
				margin-top 2.5rem

			.tag-pills
				display flex
				flex-wrap wrap
				margin -0.25rem -0.25rem

				.tag-pill
					display flex
					align-items center
					padding 0.35rem 0.25rem 0.35rem 0.5rem
					background-color $primary-faded
					margin 0.25rem 0.25rem
					border-radius $border-radius

					i
						margin-left 0.5rem
						cursor pointer
						font-size 14px
						padding 0.15rem
