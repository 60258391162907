/* v1.0.0 */

.ladda-button {
	position: relative;
}

.ladda-button .ladda-spinner {
	position: absolute;
	z-index: 2;
	display: inline-block;
	width: 32px;
	height: 32px;
	top: 50%;
	margin-top: 0;
	opacity: 0;
	pointer-events: none;
}

.ladda-button .ladda-label {
	position: relative;
	z-index: 3;
}

.ladda-button,
.ladda-button .ladda-spinner,
.ladda-button .ladda-label {
	transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important;
}

.ladda-button[data-style='zoom-out'],
.ladda-button[data-style='zoom-out'] .ladda-spinner,
.ladda-button[data-style='zoom-out'] .ladda-label {
	transition: 0.3s ease all !important;
}

.ladda-button[data-style='expand-right'] .ladda-spinner {
	right: -6px;
}

.ladda-button[data-style='expand-right'][data-loading] {
	padding-right: 56px;
}

.ladda-button[data-style='expand-right'][data-loading] .ladda-spinner {
	opacity: 1;
}

.ladda-button[data-style='zoom-out'] {
	overflow: hidden;
}

.ladda-button[data-style='zoom-out'] .ladda-spinner {
	left: 50%;
	margin-left: 32px;
	transform: scale(2.5);
}

.ladda-button[data-style='zoom-out'] .ladda-label {
	position: relative;
	display: inline-block;
}

.ladda-button[data-style='zoom-out'][data-loading] .ladda-label {
	opacity: 0;
	transform: scale(0.5);
}

.ladda-button[data-style='zoom-out'][data-loading] .ladda-spinner {
	opacity: 1;
	margin-left: 0;
	transform: none;
}
