$box-rest-opacity = 0.4
$box-hover-opacity = 0.6
$box-active-opacity = 0.85

$new-assignments-border = $background

$class-border()
	&:before
		position absolute
		width 4px
		left 0
		top 0
		bottom 0
		display block
		content ''

$roster-sizing()
	$width-sizing()
	width 100%
	max-width 88rem
	padding 2rem 4rem


.roster-view
	$roster-sizing()

	.title-row
		margin-bottom 0.75rem
		display flex
		justify-content: space-between
		flex-direction: row

		.view-title
			padding 0 0.25rem
			display flex
			align-items flex-end
			line-height 1

			.name
				$bold()
				font-size 1.5rem
				color $secondary-font

			.difficulty
				color $faded-font
				font-size 0.875rem
				margin-left 0.5rem

	.no-matches
		font-size 0.9375rem
		margin-top 1rem
		text-align center

	.filter-controls
		display flex

		.filter-select-input-group
			flex 1 1 0%

		.filter-admin
			display flex
			flex-shrink 0
			margin-left 1.35rem

			& > *
				font-size 0
				width 53px
				cursor pointer
				color $primary
				border-radius $border-radius

				& + *
					margin-left 0.5rem

				&:hover
					color $primary

				&.active
					color $primary-font
					background-color $primary-faded

				i
					$center()
					font-size 2.5rem

					&.smaller
						font-size 2rem

	.character-assignment-text
		text-align center
		margin 1rem 0 2rem

	.bosses
		&.minimal
			display flex
			flex-wrap wrap
			justify-content center
			margin -0.5rem


.roster-view .minimal-boss
	padding 1.25rem
	background-color $foreground
	border 1px solid $card-border
	border-radius $border-radius
	height 21rem
	width 17rem
	margin 0.5rem

	.panel
		img
			display block
			width 100%
			margin 0 auto 0.75rem

		.name
			$bold()
			font-size 1.3125rem
			text-align center


.roster-view .boss
	position relative
	margin-top 2rem
	background-color $foreground
	border 1px solid $card-border
	border-radius $border-radius
	min-height 25rem

	.content-tabs
		position absolute
		display flex
		top 21rem
		left -2.85rem
		transform-origin 0 0
		transform rotate(-90deg)

		.tab
			padding 0.75rem 1.5rem
			background-color $foreground
			border 1px solid $card-border
			border-bottom 0
			border-top-left-radius $border-radius
			border-top-right-radius $border-radius
			cursor pointer
			opacity 0.75

			& + .tab
				margin-left 0.5rem

			&.active
				border-color $primary
				opacity 1

			.text
				text-rendering optimizeLegibility
				letter-spacing 1.5px
				font-weight bold
				font-size 18px

	.overflow-menu
		position absolute
		right 2rem
		top 2rem

		i
			display block
			font-size 1.5rem
			padding 0.5rem
			cursor pointer
			transform rotate(90deg)

		.menu-widget
			right -1rem

	.boss-tab-notes
		display flex
		padding 1.25rem 5rem 2.5rem 2.5rem

		.left
			margin-right 2rem

			.boss-image
				display block
				width 8rem

		.content
			flex 1 1 0%

			.name-row
				display flex
				align-items center
				justify-content space-between
				padding-top 0.85rem

				.boss-name
					$bold()
					font-size 1.3125rem

				.button
					margin-left 2rem

			.notes
				margin-top 1rem

				.no-notes
					color $faded-font

	.boss-tab-content
		display flex
		flex-direction column
		padding 1.25rem 2.5rem 2.5rem

		.top-row
			display flex

			.current-assignments
				flex 1 1 0%
				display flex
				flex-direction column
				justify-content center
				margin-left 1.85rem
				margin-right 2.5rem

				.role + .role
					margin-top 1.5rem

		.panel
			flex-shrink 0
			text-align center
			margin 0 auto

			img
				display block
				width 22rem
				margin 0 auto 0.75rem

			.name
				$bold()
				font-size 1.3125rem

.roster-view .current-assignments
	.role
		display flex

		& + .role
			margin-top 1rem

		.role-icon-wrapper
			flex-shrink 0

			img
				width 2.5rem
				height @width
				opacity $faded-opacity

		.content
			display flex
			align-items center
			flex 1 1 0%
			margin-left 1rem

			.no-one-rostered
				color $super-faded-font
				font-size 0.875rem

			.assignments
				display flex
				margin -0.25rem
				flex 1 1 0%
				flex-wrap wrap

				.character-box
					$class-border()
					position relative
					display flex
					align-items center
					justify-content center
					text-align center
					overflow hidden
					user-select none
					color $secondary-font
					width 6.35rem
					height 2.35rem
					font-size 0.875rem
					border-radius $border-radius
					border-top-left-radius 0
					border-bottom-left-radius 0
					cursor pointer
					margin 0.25rem
					background $focus-box-linear-gradient-selected

					&.admin
						width @width + 1.5
						padding-right 1.5rem

					.remove-character
						display flex
						align-items center
						justify-content center
						position absolute
						right 0
						top 0
						bottom 0
						width 1.5rem
						background alpha($primary, 35%)
						font-size 0

						&:hover
							background $primary

						i
							font-size 1rem
							color $red + 20%
							padding-bottom 2px

.roster-view .change-assignments
	margin-top 3rem

	.role
		display flex

		& + .role
			margin-top 1rem

		.role-icon-wrapper
			flex-shrink 0

			img
				width 2.5rem
				height @width
				opacity $faded-opacity

		.content
			display flex
			align-items center
			flex 1 1 0%
			margin-left 1rem

			.changes
				display flex
				margin -0.25rem
				flex 1 1 0%
				flex-wrap wrap

				.change-box
					$class-border()
					position relative
					display flex
					align-items center
					justify-content center
					text-align center
					overflow hidden
					user-select none
					color $secondary-font
					width 6.35rem
					height 2.35rem
					font-size 0.875rem
					border-radius $border-radius
					border-top-left-radius 0
					border-bottom-left-radius 0
					cursor pointer
					margin 0.25rem
					background $focus-box-linear-gradient-hover-bright

					&.added:before
						background-color $green

					&.removed:before
						background-color $red

.roster-view .new-assignments
	margin-top 2rem
	border 1px solid $new-assignments-border
	border-radius $border-radius

	.tabs
		display flex
		border-bottom 1px solid $new-assignments-border

		.tab
			flex 1 1 0%
			text-align center
			padding 0.35rem
			cursor pointer

			& + .tab
				border-left 1px solid $new-assignments-border

			&:hover
				background-color alpha($primary, 10%)

			&.active
				background-color alpha($primary, 20%)

			img
				width 1.75rem
				height @width
				opacity $faded-opacity

	.limit-wrapper
		margin 0.75rem 3.5rem 0.35rem
		display flex
		align-items center
		color $faded-font

		.help-icon
			margin-left 0.75rem
			font-size 18px
			color $grey

		.limit
			margin-left 0.85rem
			margin-right @margin-left
			width 3rem

			&.limited
				.input
					background-color alpha($red, $faded-opacity)

			.input
				text-align center


	.limited-banner
		padding 0.75rem 3.5rem 0.35rem
		color $red

	.content
		padding 1rem

		&.limited
			.character:not(.active)
				cursor not-allowed

		.character-wrapper
			display flex

			& + .character-wrapper
				margin-top 0.65rem

			.assigned-icon-wrapper,
			.expand-icon-wrapper
				position relative
				flex-shrink 0
				width 2rem
				height 3.75rem

				i
					font-size 1.25rem

				.assignment-icon-inner,
				i
					$center()

			.assigned-icon-wrapper
				margin-right 0.5rem
				z-index 1

				i
					color $green

				.assignment-icon-inner
					width 20px
					height @width

					img
						width 100%
						height height

			.expand-icon-wrapper
				margin-left 0.5rem

				i
					$center()
					cursor pointer
					padding 0.35rem

		.character-middle-col
			flex 1 1 0%

		.character-loot
			margin 1rem 0.75rem 2rem

			.loot-item-row
				.left-col
					margin-right 0
					align-items center

					.item-info
						height auto

					.selection-column
						width 10rem
						margin-left 2rem

					.note
						white-space: pre-wrap
						max-width 65ch

		.character
			$class-border()
			position relative
			display flex
			width 100%
			align-items center
			justify-content space-between
			padding 0.5rem 0.75rem
			padding-left 1.25rem
			margin 0 auto
			border-radius $border-radius
			border-top-left-radius 0
			border-bottom-left-radius 0
			background $focus-box-linear-gradient-hover-bright
			user-select none
			cursor pointer

			&:hover
				background $focus-box-linear-gradient-selected

				.upgrade-prompt
					background-color $primary

			&.active,
			&.other-role-assignment,
			&.other-alt-assignment
				opacity $box-hover-opacity

				.meta-icon.type-warning
					background-color rgba($red, 0.5) !important

			&.missing
				opacity 1
				background $error-box-linear-gradient

			.left
				display flex
				align-items center
				flex-shrink 0
				width 12rem
				min-width 33%

				.thumbnail
					border-radius $border-radius
					width 2.75rem
					height @width
					margin-right 1rem

				.details
					flex 1 0 0%

					.name
						$bold()
						font-size 1.15rem

					.secondary
						color $secondary-font

			.meta-container
				flex 1 1 0%

				.meta
					position relative
					display flex

					.meta-item
						flex 1
						display flex
						align-items center
						justify-content: center
						color $secondary-font

						&:before,
						&:after
							bottom 65%

					.character-meta-icons
						flex 1
						display flex
						justify-content end

						.meta-icon
							display flex
							align-items: center
							justify-content: center
							width 2rem
							height @width
							border-radius $border-radius
							padding 0.2rem
							cursor default

							&.type-warning
								background-color rgba($red, 0.3)

							& + .meta-icon
								margin-left 0.15rem

							i
								position: relative
								bottom 1px
								font-size 1.15rem

							img
								width 100%
								border 1px solid transparent
								border-radius @border-radius

								&.character-thumb
									border-color $black

			.missing-character
				$bold()


.copy-rostered-modal,
.copy-changes-modal
	.inputs
		width 95%
		margin 0 auto 1rem

	textarea
		margin-top 1rem
		resize vertical
		min-height 20rem

.copy-changes-modal
	label
		display block
		margin-top 1rem
		text-align left

	p
		text-align left
		margin-top 0.5rem

		code
			font-family monospace
			border-radius 2px
			padding 0 2px
			background-color $light-dark

	textarea
		margin-top 0.25rem
		min-height 5rem

form.modal-content.boss-notes-editor
	width 95%
	max-width 50rem

	.tabs
		display flex
		margin 0 1rem 0.5rem

		.tab
			padding 0.65rem 1rem
			font-size 0.85rem
			color $faded-font
			border 1px solid transparent
			cursor pointer
			border-radius $border-radius

			&.active
				color $primary-font
				border-color $card-border
				background-color $input-background

		.formatting-help
			margin-left auto
			text-decoration underline

	.notes-content
		display: flex
		flex-direction: column
		padding-left 1rem
		padding-right @padding-left
		margin 0 auto 1rem
		text-align: left
		min-height 20rem

		.markdown-body
			flex-grow 1
			padding 0.75rem
			border 1px solid $input-background
			border-radius $border-radius
			height 100%

	.input
		resize vertical
		min-height 20rem
