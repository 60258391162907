.status-banner
	$bold()
	position fixed
	top 0
	left 0
	right 0
	height 2.25rem
	opacity 0
	color $white
	transition all 250ms ease, width 0 linear
	box-shadow 0 3px 3px $header
	text-shadow 0 1px 1px $foreground
	pointer-events none
	text-align center
	line-height "calc(%s - 1px)" % @height
	z-index 100

	&.show
		opacity 1

	&.success
		background-color $green

	&.error
	&.socket
		background-color $red
