.roster-view-new .section-assignment-controls .character
	position: relative
	border-top 1px solid $light-100

	&.assigned
		&::before
			display block
			content ''
			position: absolute
			left 0
			top 0
			bottom 0
			width 5px
			background-color alpha($primary, 95%)


.roster-view-new .section-assignment-controls .character .character-loot
	padding 1rem $section-assignment-controls-px 2rem

	.loot-item-row
		.left-col
			margin-right 0
			align-items center

			.item-info
				flex-shrink:  0
				height auto
				width 14rem

				img
					width 2rem
					height @width

			.selection-column
				flex-shrink 0
				width 10rem
				margin-left 2rem
				margin-right 1rem

				.dps-change
					color $red
					font-size 0.85rem

					&.gain
						color $green

					.divider
						color $light-400

			.note
				white-space: pre-wrap
				max-width 65ch


.roster-view-new .section-assignment-controls .character .character-row
		display flex
		align-items: center
		padding 0.65rem $section-assignment-controls-px

		&.assigned
			background-color alpha($primary, 20%)


.roster-view-new .section-assignment-controls .character .character-row .character-details
	position: relative
	flex-shrink 0
	width 10rem

	.class-colour-block
		position: relative
		left -1px
		border-radius 1px
		height 5px
		width 8.5rem
		margin-bottom 0.25rem
		opacity: 0.75

	// .assigned-chip
	// 	display: none
	// 	position absolute
	// 	top -7px
	// 	left 9rem
	// 	background-color alpha($primary, 70%)
	// 	border-radius $border-radius
	// 	color alpha($darker-light, 75%)
	// 	padding 0.15rem 0.35rem
	// 	font-size 0.75rem
	// 	letter-spacing: 0.5px

	.details
		display flex
		align-items: center

		img
			width 2rem
			height @width
			border-radius $border-radius
			margin-right 0.75rem
			opacity: 0.8

		.naming
			.primary
				font-size 1rem

			.secondary
				margin-top 1px
				font-size 0.875rem
				color $faded-font


.roster-view-new .section-assignment-controls .character .character-row .character-meta
	flex 1 1 0%
	display: flex
	flex-direction: row
	margin-left 1rem
	margin-right 2rem

	.character-meta-stats
		flex 1 1 0%
		display: flex

		.meta-item
			flex 1
			display: flex
			align-items: center
			justify-content: center

			.item-inner
				padding-left 0.25rem
				padding-right @padding-left

				&.clickable
					cursor pointer
					border-bottom 1px solid $light-300

					&:hover
						border-color $light-500

						.stat-value
							color $light-800

						.stat-description
							color $light-600

				.stat-value
					color $light-600

				.stat-description
					color $light-400

	.character-meta-icons
		flex-shrink: 0
		width 8.5rem // 4x 2rem + 3x 0.15rem
		display flex
		justify-content: end

		.meta-icon
			display flex
			align-items: center
			justify-content: center
			width 2rem
			height @width
			border-radius $border-radius
			padding 0.2rem
			cursor default

			&.type-warning
				background-color rgba($red, 0.3)

			& + .meta-icon
				margin-left 0.15rem

			i
				position: relative
				bottom 1px
				font-size 1.15rem

			img
				width 100%
				border 1px solid transparent
				border-radius @border-radius

				&.character-thumb
					border-color $black

				&.loot-outdated
					position relative
					left 1px // the image's shadow makes it odly weighted so it needs to be shifted


.roster-view-new .section-assignment-controls .character .character-row .character-actions
	flex-shrink 0

	.assign-button
		width 6rem
