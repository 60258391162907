.guild-settings
	.billing-link:not(.button)
		$bold()
		color $blue-link + 15%

	.roster-bosses-link
		display block
		position relative
		margin-top 2rem
		padding 1.25rem
		padding-left 1rem
		margin-bottom 0.75rem
		border 1px solid $card-border
		border-radius $border-radius
		border-bottom-left-radius 0
		border-top-left-radius 0
		border-left none
		background $focus-box-linear-gradient-hover
		text-decoration underline

		&:hover
			background $focus-box-linear-gradient-hover-bright

		&:before
			position absolute
			width 4px
			left @width * -1
			top -1px
			bottom -1px
			display block
			content ' '
			z-index 1
			background-color $primary-pop

	.roster-bosses-list
		.roster-boss-item
			position relative
			display flex
			align-items center
			// justify-content space-between
			padding 0.75rem
			padding-left 1rem
			margin-bottom 0.75rem
			border 1px solid $card-border
			border-radius $border-radius
			border-bottom-left-radius 0
			border-top-left-radius 0
			border-left none
			background $focus-box-linear-gradient
			user-select none
			cursor grab

			&.placeholder
				visibility hidden

			&.dragged
				z-index 5

			img
				border-radius $border-radius
				width 7rem
				height @width
				margin-right 0.85rem

			.name
				$bold()
				font-size 1.15rem

	.admin-settings
		.user-admins
			min-height 22rem

			.action-wrapper
				position relative

			.user-admin-select
				margin-top 0.25rem
				right -2rem

				.react-select-prefix__option
					display: flex
					align-items: center

				.option-image
					width 1.6rem
					height @width
					border-radius: $border-radius
					margin-right 0.35rem

			.user-admin-items
				max-width 30rem
				margin 0 auto
