.select-input
	position relative

	&.disabled
		.input i
			display none

		.menu-widget
			display none

	.input
		overflow ellipsis
		cursor pointer
		position relative
		padding-right 2.5rem

		&.error
			border-color $input-active-border-colour-error

		i
			$vertical-center()
			right 1rem
			color $secondary-font
			line-height 1.2rem
			font-size 0.85rem

	&.expanded
		.input
			border-color $input-active-border-colour
			box-shadow $input-active-box-shadow

	.menu-widget
		margin-top 0.25rem
		width 100%
		max-width 100%
		border-color $input-active-border-colour

		.highlighted,
		.selected
			$super-bold()
			background-color $primary-super-faded
			color $primary-font-pop
