.wow-style
	&.text
		&.death_knight
			color $death-knight-colour

		&.paladin
			color $paladin-colour

		&.warrior
			color $warrior-colour

		&.hunter
			color $hunter-colour

		&.shaman
			color $shaman-colour

		&.demon_hunter
			color $demon-hunter-colour

		&.druid
			color $druid-colour

		&.monk
			color $monk-colour

		&.rogue
			color $rogue-colour

		&.mage
			color $mage-colour

		&.priest
			color $priest-colour

		&.warlock
			color $warlock-colour

		&.evoker
			color $evoker-colour

	&.bg
		&.death_knight
			background-color $death-knight-colour

		&.paladin
			background-color $paladin-colour

		&.warrior
			background-color $warrior-colour

		&.hunter
			background-color $hunter-colour

		&.shaman
			background-color $shaman-colour

		&.demon_hunter
			background-color $demon-hunter-colour

		&.druid
			background-color $druid-colour

		&.monk
			background-color $monk-colour

		&.rogue
			background-color $rogue-colour

		&.mage
			background-color $mage-colour

		&.priest
			background-color $priest-colour

		&.warlock
			background-color $warlock-colour

		&.evoker
			background-color $evoker-colour

	&.bg-before
		&.death_knight:before
			background-color alpha($death-knight-colour, $faded-opacity)

		&.paladin:before
			background-color alpha($paladin-colour, $faded-opacity)

		&.warrior:before
			background-color alpha($warrior-colour, $faded-opacity)

		&.hunter:before
			background-color alpha($hunter-colour, $faded-opacity)

		&.shaman:before
			background-color alpha($shaman-colour, $faded-opacity)

		&.demon_hunter:before
			background-color alpha($demon-hunter-colour, $faded-opacity)

		&.druid:before
			background-color alpha($druid-colour, $faded-opacity)

		&.monk:before
			background-color alpha($monk-colour, $faded-opacity)

		&.rogue:before
			background-color alpha($rogue-colour, $faded-opacity)

		&.mage:before
			background-color alpha($mage-colour, $faded-opacity)

		&.priest:before
			background-color alpha($priest-colour, $faded-opacity)

		&.warlock:before
			background-color alpha($warlock-colour, $faded-opacity)

		&.evoker:before
			background-color alpha($evoker-colour, $faded-opacity)


	&.font-colour
		&.death_knight
		&.demon_hunter
		&.shaman
		&.warlock
			color $white !important

			& *
				color $white !important
