.select-guild
	padding 8vh 2rem 2rem
	text-align center

	.title
		$super-bold()

	.guild-group + .guild-group
		margin-top 6rem;

	.description
		margin-top 1.85rem

	.guild-options
		margin-top 1.15rem

	.guild-wrapper
		margin 1rem auto 0
		width 24rem
		max-width 95%

	.guild
		display flex
		flex-direction row
		justify-content space-between
		align-items center
		padding 0.75em 1rem
		text-align left
		border 1px solid $card-border
		border-radius $border-radius
		background-color $foreground
		cursor pointer
		transition box-shadow 150ms ease-out

		&:hover
			box-shadow 0 1px 6px 0 $light-dark

		.name
			$bold()
			font-size 1.15rem

		.realm
			color $half-light

		img
			height 3.5rem
			top 0.5rem
			right 0.5em
			opacity 0.85

	.no-guilds
		margin 1rem auto 0
		width 24rem
		max-width 95%
		line-height 1.3

		.current-region
			$bold()
			margin-top 0.35rem

		.button
			margin 3rem auto 0

	.missing-guilds-notice
		margin 3rem auto 0
		color $super-faded-font
		max-width 65ch

		a
			$link-styling()

