.pane-widget
	position: absolute
	z-index: 20
	background-color $background
	border-radius $small-border-radius
	box-shadow 0 0 3px $card-border

	&.with-border
		border 1px solid
		border-color $input-active-border-colour
