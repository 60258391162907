$font-family()
	font-family 'Lato', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Cantarell, 'Helvetica Neue', sans-serif

$brand-font-family()
	font-family 'Berkshire Swash', Ubuntu, 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Cantarell, 'Helvetica Neue', sans-serif

$icon-font()
	font-family 'Material Icons'
	font-weight normal
	font-style normal
	font-size 20px
	display inline-block
	line-height 1
	text-transform none
	letter-spacing normal
	word-wrap normal
	white-space nowrap
	direction ltr
	-webkit-font-smoothing antialiased
	text-rendering optimizeLegibility
	-moz-osx-font-smoothing grayscale
	font-feature-settings 'liga'

$super-bold()
	font-weight 900

$bold()
	font-weight 700

$vertical-center()
	position absolute
	top 50%
	transform translateY(-50%)

$horizontal-center()
	position absolute
	left 50%
	transform translateX(-50%)

$center()
	position absolute
	top 50%
	left 50%
	transform translate(-50%, -50%)

$view-padding()
	padding 2rem 2rem 2rem

$flex-expand()
	flex-grow 1
	flex-shrink 1
	flex-basis auto
	min-height 0
	min-width 0

$link-styling()
	text-decoration underline

	&:hover
		color $blue

$linkify()
	$link-styling()
	color $blue-link

$overflow-auto()
	overflow auto
	overflow-scrolling auto
	-webkit-overflow-scrolling touch

$scrollable()
	display flex
	flex-direction column
	height 100%


$width-sizing()
	width 90%
	max-width 80rem
	margin 0 auto
