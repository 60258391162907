.roster-view-new .character-view-section
	.message-text
		text-align: center
		margin-top 3rem

	.boss-tiles
		margin-top 2rem
		display flex
		flex-wrap wrap
		justify-content: center
		gap 1rem 1.25rem

	.minimal-boss
		position: relative
		padding 0.75rem 1.25rem
		background-color $foreground
		border 1px solid $card-border
		border-radius $border-radius
		height 18rem
		width 15rem

		.boss-image
			display block
			width 100%
			margin 0 auto 0.75rem

		.role-image
			position: absolute
			top 0.35rem
			right 0.5rem
			width 1.75rem
			height @width
			opacity $faded-opacity

		.name
			$bold()
			font-size 1.3125rem
			text-align center
