.character-details-widget
	position: relative
	flex-shrink 0
	width 10rem

	.class-colour-block
		position: relative
		left -1px
		border-radius 1px
		height 5px
		width 8.5rem
		margin-bottom 0.25rem
		opacity: 0.75

	.details
		display flex
		align-items: center

		img
			width 2rem
			height @width
			border-radius $border-radius
			margin-right 0.75rem
			opacity: 0.8

		.naming
			.primary
				font-size 1rem

			.secondary
				margin-top 1px
				font-size 0.875rem
				color $faded-font
