.guild-already-setup
	.content
		text-align left

		ol
			list-style decimal

			li
				margin-top 0.35rem

				.directions
					$super-bold()

		.help
			margin-top 3rem


.setup-guild-like-view
	padding 8vh 2rem 2rem

	.heading
		text-align center
		width 35rem
		max-width 95%
		margin 0 auto 3rem

		h1
			$super-bold()

		.submit-buttons
			margin-top 3rem
			margin-bottom 1rem


	.submit-buttons
		position relative

		*
			margin-right auto
			margin-left auto

		.need-own-character
			position absolute
			width 100%
			text-align center
			color $red
			font-size 0.875rem
			margin-top 0.5rem

	.content
		display flex
		max-width 68rem
		margin 0 auto

		& + .submit-buttons
			margin-top 5rem
			margin-bottom 3rem

	.rank-groups
		width 100%
		padding 1.5rem 2rem
		background-color $foreground
		border 1px solid $card-border
		border-radius $border-radius


.setup-guild-like-view .rank-group
	margin-bottom 5rem

	&:last-child
		margin-bottom 0

	.rank-label
		display flex
		align-items center
		justify-content space-between
		margin-bottom 1rem
		user-select none

		.controls
			display flex
			align-items center

			.rank-name
				width 18rem

				&.view
					$bold()
					font-size 1.15rem
					color $secondary-font

			.expand-control
				margin-left 0.5rem
				padding 0.5rem
				cursor pointer

			.selected-text
				margin-left 1rem
				line-height 1

		.actions
			display flex

			.mass-toggle
				cursor pointer
				padding 0.35rem 0.5rem
				font-size 0.85rem
				text-transform uppercase
				color $faded-font
				line-height 1

				&.add:hover
					color $green

				&.remove:hover
					color $red

	.characters
		display flex
		flex-wrap wrap
		margin -0.35rem

		.character
			position relative
			width 15rem
			margin @margin * -1
			margin-left "calc(%s + 4px)" % (@margin)
			display flex
			align-items center
			padding 0.5rem 0.5rem 0.5rem 0.75rem
			margin-bottom 0.75rem
			border 1px solid $card-border
			border-radius $border-radius
			border-bottom-left-radius 0
			border-top-left-radius 0
			border-left none
			background $focus-box-linear-gradient-hover
			cursor pointer
			user-select none

			&:hover
				background $focus-box-linear-gradient-hover-bright

			&:before
				position absolute
				width 4px
				left @width * -1
				top -1px
				bottom -1px
				display block
				content ' '
				z-index 1
				// border-bottom-left-radius $border-radius
				// border-top-left-radius $border-radius

			&.selected
				// background-color alpha($primary, $faded-opacity)
				background $focus-box-linear-gradient-selected
				border-color $primary-super-pop
				color $darker-light

			img
				border-radius $border-radius
				width 3rem
				height @width
				margin-right 0.65rem

			.details
				flex 1 0 0%

				.name
					$bold()
					font-size 1.15rem

				.secondary
					color $secondary-font
