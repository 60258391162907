.roster-view-new .section-buffs
	margin-left 1.4rem
	display: flex
	flex-wrap: wrap
	gap 0.85rem 2rem

	.buff-group
		> .title
			$bold()
			display: table
			margin-bottom 0.25rem
			margin-left 1.25rem

		> .content
			display: table

	.buff-row
		display: table
		position relative
		display: flex
		justify-content: space-between
		font-size 0.9375rem
		cursor default
		padding 0.15rem 0

		&.active
			color $green

			.name
				color $faded-font

		&.inactive
			color $red

			.value i
				display block
				margin-top 1px
				font-size 1.125rem

		> .name
			overflow ellipsis
			padding-left 1.25rem

		> .value
			$vertical-center()
			left -3px
			width 1rem
			text-align center

	.buff-row
		.buff-contributors
			$horizontal-center()
			bottom 120%
			position: absolute
			background-color $dark-dark
			z-index: 1
			padding 0.5rem 0.75rem
			border-radius $border-radius
			width 12.5rem
			color $primary-font
			border 1px solid $light-200

			.title
				$bold()
				margin-bottom 0.35rem

			.abilities
				.ability
					display flex
					margin-top 0.15rem

					.count
						margin-right 0.5rem
						text-align right
						color $secondary-font

					.ability-name
						opacity 0.9
