.util-ability-icon
	border-radius $border-radius

	img
		border-radius @border-radius
		width 100%

	.placeholder
		border 1px dashed $light-300
		height 100%
		border-radius @small-border-radius
