$settings-padding-top = 3.75rem
$settings-padding-bottom = 5rem
$settings-padding-side = 2.5rem

$settings-split-item-left-overflow = 0.6rem
$settings-split-panel-width = 15.75rem


.settings-page
	position fixed
	top 0
	bottom 0
	left 0
	right 0
	background-color $background
	z-index 30
	display flex
	flex-direction row

	& > .panel
		flex 1 0 15rem
		background-color $foreground

		.panel-content
			padding 3.75rem 1.25rem 3.75rem 2rem
			margin-left auto
			width 15rem

			.group
				margin-bottom 1.75rem

				.group-heading
					$super-bold()
					color $super-faded-font
					font-size 0.75rem
					margin-left 0.65rem
					margin-bottom 0.2rem
					text-transform uppercase

			a
				$super-bold()
				position relative
				letter-spacing 0.25px
				display block
				padding 0.5rem 0.65rem
				border-radius $border-radius
				margin-bottom 0.15rem

				&:hover
					color $primary-font-pop

				&.active
					background-color $primary-faded
					color $primary-font-pop

				.link-alert
					$vertical-center()
					width 8px
					height @width
					position absolute
					right -12px
					background-color $red
					border-radius 50%

	& > .content-wrapper
		$overflow-auto()
		flex 1 1 50rem
		display flex
		align-items flex-start
		flex-direction row

		& > .close-button
			position relative
			padding-top 3.75rem
			flex-shrink 0
			color $faded-font
			width 2.25rem

			i
				position fixed
				font-size @width
				display block
				cursor pointer

				&:hover
					color $primary-font

			.button-label
				position fixed
				font-size 0.75rem
				text-align center
				margin-top 2.6rem
				width @width

				// icon isn't in the center...
				margin-left -1px


		& > .settings-content
			max-width 46rem
			flex 1 1 0%

			&:not(.no-padding)
				padding $settings-padding-top $settings-padding-side $settings-padding-bottom

.settings-page .settings-content
	.settings-heading
		$super-bold()
		color $primary-font-pop
		text-transform uppercase
		font-size 1rem
		margin-bottom 1.5rem

	.settings-split-content
		display flex

		.list-content-container
			$overflow-auto()
			position absolute
			top 0
			bottom 0
			width $settings-split-panel-width
			padding-top $settings-padding-top
			padding-bottom $settings-padding-bottom
			padding-left $settings-padding-side
			padding-right 0.5rem

		.list-content
			.help-item
				padding 0.4rem $settings-split-item-left-overflow
				transform translateX($settings-split-item-left-overflow * -1)
				margin-top 1rem
				font-size 0.875rem
				color $secondary-font

			.list-item
				display block
				padding 0.4rem $settings-split-item-left-overflow
				cursor pointer
				transform translateX($settings-split-item-left-overflow * -1)
				margin-bottom 0.25rem
				border-radius $small-border-radius

				&.placeholder
					visibility hidden

				&.dragged
					background-color $primary-faded
					transform none
					z-index 5

				&.add-new
					margin-bottom 0.75rem
					text-decoration underline
					color $secondary-font

					&.active
						text-decoration none

				&.active
					background-color $primary-faded

				&.active,
				&:hover
					color $primary-font-pop

					.description
						color $primary-font !important

				.name
					$bold()

				.description
					margin-top 0.2rem
					color $secondary-font
					font-size 0.875rem

		.details-content
			flex 1 1 0%
			margin-left $settings-split-panel-width
			padding-top $settings-padding-top
			padding-bottom $settings-padding-bottom
			padding-right $settings-padding-side
			padding-left 1.75rem

			.button-group
				margin-top 2.5rem

	.settings-field-group
		& + .settings-field-group
			margin-top 4rem

		.group-heading-row
			display flex
			justify-content: space-between
			align-items: flex-start
			gap 1rem
			margin-bottom 1.25rem

			.group-description
				margin-bottom 0

		.group-heading
			$super-bold()
			font-size 0.875rem
			text-transform uppercase
			color $faded-font
			margin-bottom 0.5rem

		.group-description
			font-size 0.875rem
			margin-bottom 1.25rem
			color $faded-font

		.group-item
			& + .group-item
				margin-top 0.85rem
				padding-top 0.85rem
				border-top 1px solid $input-background + 3%

	.help-block,
	.settings-description
		color $secondary-font
		margin-bottom 1.75rem

	.hero-view
		max-width 30rem
		margin 0 auto

		& + *
			margin-top 3rem

		.billing-link
			display table
			margin 0 auto

		.hero-icon
			display block
			text-align center
			margin 0 auto 2rem
			width 13rem

		.hero-description
			text-align center
			margin-bottom 2rem


	.tagging-group-switch
		margin-top 2rem

	.rank-groups
		max-width 30rem
		margin 0 auto

		&.margin-top
			margin-top 2rem

		.rank-group + .rank-group
			margin-top 2.5rem

		.rank-group
			.rank-info
				margin-bottom 0.5rem
				color $secondary-font

			.rank-name
				$bold()

			.admin-rank
				margin-left 0.5rem
				color $faded-font

	.settings-character-row
		position relative
		display flex
		align-items center
		justify-content space-between
		padding 0.75rem
		padding-left 1rem
		margin-bottom 0.75rem
		border 1px solid $card-border
		border-radius $border-radius
		border-bottom-left-radius 0
		border-top-left-radius 0
		border-left none
		background $focus-box-linear-gradient
		user-select none

		&:before
			position absolute
			width 4px
			left @width * -1
			top -1px
			bottom -1px
			display block
			content ' '
			z-index 1

		.left
			display flex
			align-items center

		.thumbnail
			border-radius $border-radius
			width 3rem
			height @width
			margin-right 0.85rem

		.details
			flex 1 0 0%

			.name
				$bold()
				font-size 1.15rem

			.secondary
				color $secondary-font
