.util-hotkey-combo
	display: inline-block

	& > *
		display: inline-block

.util-hotkey-combo-joiner
	$bold()
	margin-left 5px
	margin-right 5px

.util-hotkey
	display: inline-block
	font-size 1rem
	color $faded-font

	.key-edge
		min-width 24px
		height 24px
		padding 6px 10px
		border-radius 4px
		border-bottom 3px solid $light-200
		border-left 2px solid $light-100
		border-right 1px solid $light-100
		box-shadow 0 1px 2px 1px rgba(0, 0, 0, 0.8)
		background-color $light-100
		background linear-gradient(to right, $light-100, $light-100, $light-100)
		display inline-flex
		align-items center
		justify-content center

		.key-top
			font-family -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif
			line-height 1.0
