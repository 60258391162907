@require 'nib'
@require 'colours'
@require 'variables'
@require 'mixins'

global-reset()

*
	box-sizing border-box

// disable OS X over-scroll
// html, body
// 	overflow hidden
// 	width 100%
// 	height 100%

body
	$font-family()
	color $primary-font
	font-size 16px
	min-height 100%
	line-height 1.2
	-webkit-font-smoothing antialiased
	background-color $background

a
	color inherit
	text-decoration none

	&.linkify
		$linkify()

b, strong
	$bold()

p
	margin 0 0 1rem

h1
	letter-spacing -1px
	font-size 2rem
	line-height 2.5rem

	&.big
		font-size 3rem
		margin-bottom 1.5rem

h2
	font-size 1.1875rem

h1, h2, h3, h4, h5
	margin 0 0 1rem
	font-weight 600


// #root
// 	position absolute
// 	top 0
// 	right 0
// 	bottom 0
// 	left 0

#root
	$overflow-auto()
	min-height 100%
	min-height 100vh

// .page-container
// 	height 100%
// 	display flex
// 	flex-direction column

// .page-container
// 	$scrollable()

// .view-container
// 	$scrollable()


@require 'button'
@require 'card'
@require 'input'
@require 'tooltip'
@require 'wow'
@require 'markdown'

@require 'settings'

@require '../components/**'

@require 'content-card'


.brand-name
	$super-bold()

.styled-link
	color $blue-link


.placeholder
	color $placeholder-text

$placeholder()
	color $placeholder-text
	opacity 1

::-webkit-input-placeholder
	$placeholder()

::-moz-placeholder
	$placeholder()

:-ms-input-placeholder
	$placeholder()

:-moz-placeholder
	$placeholder()


.empty-state
	text-align center
	margin 5% auto 1rem
	max-width 32rem

	i, img
		display block
		margin-bottom 1rem

	i
		font-size 10rem
		color $half-dark

	img
		margin-left auto
		margin-right auto

	.main
		$bold()
		font-size 2rem

	.secondary
		margin-top 1rem


@require 'ladda.css'


.support-beacon
	position fixed
	bottom 1.35rem
	right 1.35rem
	width 3.5rem
	height @width
	border-radius 50%
	background-color $primary
	display flex
	align-content center
	justify-content center
	z-index 200
	box-shadow 0 2px 5px 0 rgba(0,0,0,.26)
	cursor pointer

	&:hover
		background-color $primary-pop
		color $primary-font-pop

	i
		$center()
		font-size 1.75rem
		margin-top -1px

.react-select
	.input
		border none
		background transparent

	&.regular
		.react-select-prefix__value-container
			font-size $input-font-size

			.react-select-prefix__placeholder,
			.react-select-prefix__input-container
				padding $input-padding

			.react-select-prefix__single-value
				padding $input-padding

	&.big
		.react-select-prefix__value-container
			font-size $big-input-font-size

			.react-select-prefix__placeholder,
			.react-select-prefix__input-container
				padding $big-input-padding

			.react-select-prefix__single-value
				padding $big-input-padding

	.react-select-prefix__control
		cursor pointer
		background-color $input-background
		border-color $card-border
		border-radius $small-border-radius
		min-height 0

		&:hover
			border-color @border-color

		&.react-select-prefix__control--is-focused
			box-shadow none
			border-color $input-active-border-colour

		.react-select-prefix__value-container
			line-height inherit
			padding 0

	.react-select-prefix__placeholder
		$placeholder()
		margin 0
		overflow ellipsis

	.react-select-prefix__input-container
		margin 0

	.react-select-prefix__menu
		background-color $input-background
		border-radius $small-border-radius
		color $primary-font
		border 1px solid
		border-color $input-active-border-colour
		box-shadow 0 0 3px $card-border
		margin-top 0.25rem
		z-index: 20

	.react-select-prefix__option
		cursor pointer
		background transparent
		color inherit
		margin 0 0.5rem
		padding 0.65rem 0.75rem
		border-radius $small-border-radius
		transition all 50ms ease
		font-size 0.9375rem
		width auto

		&.react-select-prefix__option--is-focused
			background-color $primary
			color $primary-font-pop

	.react-select-prefix__single-value
		color $primary-font

.hide-input-controls
	-moz-appearance textfield

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button
		-webkit-appearance none
		margin 0
