@require '../CurrentAssignmentsSection/current-assignments'

.roster-view-new .section-changes
	.role-group
		display flex

		& + .role-group
			margin-top 1rem

		.role-icon
			flex-shrink 0
			width $current-assignments-icon-width
			height @width;
			margin-top 0.15rem

			img
				display block
				width 100%
				opacity $faded-opacity

		.content
			flex 1 1 0%
			display: flex
			align-items: center
			margin-left 0.75rem

			.changes
				display flex
				margin -0.15rem -0.2rem
				flex 1 1 0%
				flex-wrap wrap

				.change-box
					position relative
					display flex
					align-items center
					width 6.75rem // has extra since it includes the icon
					height $current-assignments-assignment-height
					margin 0.15rem 0.2rem
					padding-left calc(4px + 1.35rem) // for change colouring
					overflow hidden
					user-select none
					border-radius: $border-radius
					border-top-left-radius 0
					border-bottom-left-radius 0
					background $focus-box-linear-gradient-selected

					&::before
						position absolute
						width 4px
						left 0
						top 0
						bottom 0
						display block
						content ''

					&.added::before
						background-color $green

					&.removed::before
						background-color $red

					.name
						font-size 0.875rem
						overflow ellipsis

					.change-icon
						position absolute
						top 0
						bottom 0
						display flex
						align-items: center
						justify-content: center
						left 4px
						width 1.35rem
						color $faded-font
