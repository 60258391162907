.roster-view-new .roster-heading
	.right-group
		display flex
		align-items center

		.roster-actions-menu
			margin-right 0.5rem

			.actions-menu-items
				right: -1.5rem

	.character-search
		min-width 22vw
