.content-card
	width 95%
	max-width 28rem
	margin-top 15vh
	text-align center

	.heading
		div
			$bold()
			font-size 1.375rem
			margin-bottom 1.5rem

		i
			display table
			font-size 5rem
			color $primary
			margin 0 auto 1.5rem

			&.red
				color $red

	.button-group
		margin-top 2rem
		justify-content center

	.button
		width 10rem
