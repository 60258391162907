$header-border-colour = alpha($primary, 75%)
$header-border-colour-secondary = alpha($primary, 25%)


.headroom
  top 0
  left 0
  right 0
  z-index 20

.headroom--unfixed
  position relative
  transform translateY(0)

.headroom--scrolled
  transition all 200ms ease-in-out

.headroom--unpinned
  position fixed
  transform translateY(-100%)

.headroom--pinned
  position fixed
  transform translateY(0%)


.headroom--unfixed,
.headroom--pinned
  .status-banner
  	position relative
  	top -1px

.headroom--unpinned
	.status-banner
		top 100%
		top initial


.headroom-wrapper
	margin-bottom 5px

.headroom
	box-shadow 0 1px 4px $light-dark

.headroom--unpinned.headroom--scrolled
	box-shadow none

.header-nav
	display flex
	justify-content space-between
	align-items center
	height 3.5rem
	padding 0 1.2rem
	background-color $header
	color $primary-font
	border-bottom 2px solid $header-border-colour

	&.with-sub-nav
		border-color $header-border-colour-secondary

	.brand
		display flex
		align-items center

		img
			height 2.75rem
			// width @height
			margin-right 0.5rem

		.name
			$brand-font-family()
			font-size 1.75rem
			// color $secondary-font
			text-shadow 2px 2px 2px #000

	.guild-name
		$horizontal-center()
		$super-bold()
		font-size 1.375rem

	.links
		display flex

		.announcekit-widget
			a
				position absolute !important
				top 14px

		.link
			$super-bold()
			letter-spacing 0.25px
			font-size 1rem
			display block
			user-select none
			padding 0.6rem 0.65rem 0.45rem
			line-height 1
			color $secondary-font
			border-radius $border-radius
			cursor pointer

			&:hover
				text-decoration underline

			&.active
				// background-color $pale-light
				background-color $primary
				text-decoration none
				color $primary-font-pop

		.user-settings-link
			margin-left 0.5rem
			color $primary-font-pop
			text-decoration none !important
			font-size 1.25rem
			padding 0.5rem

		.user-nav
			position relative

			.toggler
				display flex
				align-items center
				text-decoration none

				i
					margin-left 0.35rem
					font-size 0.75rem

			.menu-widget
				right 0

.header-sub-nav
	display flex
	height 3.25rem
	background-color $header-secondary
	color $secondary-font
	border-bottom 2px solid $header-border-colour

	.sub-nav-items
		$width-sizing()
		display flex
		align-items center

		.left-items
			display flex
			align-items center
			margin-left -0.75rem

			& > *
				& + *
					margin-left 0.65rem;

		.nav-item
			$super-bold()
			letter-spacing 0.25px
			line-height 1.2
			border-radius $border-radius
			padding 0.45rem 0.75rem 0.5rem

			&.active
				background-color $primary
				color $primary-font-pop

			&.billing-link
				margin-left auto

				&.focus
					background $focus-box-linear-gradient-button
					color $primary-font-pop

		.your-loot-sheet
			position relative
			display flex

			&:hover
				.your-loot-sheet-selector
					display block

			.your-loot-sheet-selector
				$horizontal-center()
				display none
				position absolute
				top 1.75rem
				border 1px solid
				border-color $background
				border-radius $small-border-radius
				background-color $input-background
				z-index 20
				box-shadow 0 0 3px $card-border
				padding 1rem 1.5rem

			.character
				position relative
				width 15rem
				margin @margin * -1
				margin-left "calc(%s + 4px)" % (@margin)
				display flex
				align-items center
				padding 0.5rem 0.5rem 0.5rem 0.75rem
				border 1px solid $card-border
				border-radius $border-radius
				border-bottom-left-radius 0
				border-top-left-radius 0
				border-left none
				background $focus-box-linear-gradient-hover
				cursor pointer
				user-select none

				&:hover
					background $focus-box-linear-gradient-hover-bright

				&:before
					position absolute
					width 4px
					left @width * -1
					top -1px
					bottom -1px
					display block
					content ' '
					z-index 1

				&.active
					background $focus-box-linear-gradient-selected
					color $darker-light

				& + .character
					margin-top 0.75rem

				img
					border-radius $border-radius
					width 3rem
					height @width
					margin-right 0.65rem

				.details
					flex 1 0 0%

					.name
						$bold()
						font-size 1.15rem

					.secondary
						color $secondary-font
