@keyframes stretchdelay
	0%, 40%, 100%
		transform scaleY(1)
	20%
		transform scaleY(2)

.loading-component
	height 4rem
	font-size 0

	&.centered
		$center()

	&.small
		height 2.5rem

		& > div
			width 0.5rem
			margin @height * 0.25 1px 0

	& > div
		display inline-block
		margin @height * 0.25 2px 0
		width 1rem
		height 50%
		border-radius $small-border-radius
		background-color $primary
		opacity 0.85
		animation stretchdelay 1.2s infinite ease-in-out

	.r2
		animation-delay -1.1s

	.r3
		animation-delay -1.0s

	.r4
		animation-delay -0.9s

	.r5
		animation-delay -0.8s
