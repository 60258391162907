@require '../RosterOld/roster'


.loot-view
	$roster-sizing()
	padding-bottom 12rem

	.no-matches
		font-size 0.9375rem
		margin-top 1rem
		text-align center

	.filter-controls
		display flex
		margin 0 -0.25rem

		& > *
			margin 0 0.25rem

		.filter-select-input-group
			flex 1.5 1 0%

		.select-input
			flex 1 1 0%
			margin-bottom 0

			// the react-select is slightly larger for some reason
			.input
				height 100%

			&.difficulty-select
				flex 0.6 1 0%

			&.instance-select
				max-width 15rem

			&.loot-option-select
				width 10rem

		.rclc-button
			display flex
			align-items: center
			background-color $button-grey
			color $dark-light
			padding 0 0.75rem
			font-size 20px
			border-radius $small-border-radius
			border 1px solid $card-border

			&:hover
				background-color @background-color - 8%

	.secondary-controls
		display flex
		justify-content space-between
		margin 0.5rem 0

		&:empty
			display none

	.filter-items
		margin-top 0.35rem
		display: flex

		& > *
			& + *
				margin-left 2rem

	.own-character-links
		display flex
		flex-wrap wrap
		align-items center
		margin-left 0.5rem

		.link-intro
			margin-right 0.5rem

		.link
			position relative
			margin 0.2rem 0.35rem
			color $faded-font
			padding 0.25rem 0.4rem 0.4rem 0.45rem
			border-radius $small-border-radius
			font-size 1rem
			cursor pointer
			opacity 0.85
			border-bottom-left-radius 0
			border-top-left-radius 0
			border-left none
			background-color $foreground

			&:before
				position absolute
				width 3px
				left @width * -1
				top 0
				bottom 0
				display block
				content ' '
				z-index 1

			&:hover
				opacity 1
				color $secondary-font

	.bosses
		margin-top 2rem

	.loot-hotkeys,
	.reason-for-edit-ability
		text-align center
		color $faded-font
		margin-top 1.5rem
		margin-bottom 1.25rem

		& + .bosses
			margin-top 0.75rem

	.loot-hotkeys
		.text
			margin-left 0.75rem

	.char-controls
		display flex
		align-items center
		margin-top 1rem
		height 4.5rem

		.bump-button-container
			margin-left auto

		.role-filter-section
			margin-left 0.5rem

			.role-selection
				display flex
				margin-top 0.35rem

				.role
					width 2.85rem
					height @width
					padding 0.25rem
					margin-right 0.2rem
					cursor pointer
					border-radius $border-radius

					&:last-child
						margin-right 0

					&:hover
						background $focus-box-linear-gradient-hover-bright

					&.selected
						background $focus-box-linear-gradient-selected

					img
						width 100%
						height auto
						opacity $faded-opacity



.loot-view .boss
	display flex
	flex-direction column
	padding 1.5rem 1rem 1.5rem 1.75rem
	background-color $foreground
	border 1px solod $card-border
	border-radius $border-radius

	& + .boss
		margin-top 2rem

	.header
		display flex
		align-items flex-start
		justify-content space-between

		.boss-info
			display flex
			align-items center

			img
				height 5rem
				margin-right 1rem

			.name
				$bold()
				font-size 1.5rem

		.coin-control
			&.view
				$bold()
				display flex
				align-items center
				line-height 1.5

				i
					margin-left 0.5rem
					font-size 1.5rem
					color $green

	.items
		margin-top 2rem


.loot-item-row
	display flex
	align-items flex-start
	margin-bottom 1.5rem

	&:last-child
		margin-bottom 0

	.left-col
		display flex
		align-items flex-start
		margin-right 1.5rem

		.item-col
			.selection-updated
				padding 0.25rem
				color $light-400
				font-size 0.85rem

		.item-info
			display flex
			width 12rem
			height 3.125rem
			// color $epic-colour
			color $secondary-font

			a
				display flex
				align-items center

				img
					width 2.25rem
					height @width
					border-radius $small-border-radius
					border 1px solid $epic-colour
					flex-shrink 0

					&.conduit
						border-color $common-colour

				.name
					$bold()
					letter-spacing 0.75px
					margin-left 0.75rem
					font-size 0.875rem

		.selection-column
			margin-left 1rem
			width 13rem

			.add-note-prompt
				text-decoration: underline
				padding 0.15rem 0
				text-align: center
				color $light-400
				cursor pointer

			.note-input
				textarea
					padding 0.25rem 0.5rem
					resize none

			.view-note
				border 1px solid transparent
				border-radius: $border-radius
				white-space: pre-wrap

				&.clickable
					padding 0.25rem 0.5rem
					cursor pointer

					&:hover
						border-color $light-200

			&.viewable-selection
				.view-note
					margin-top 0.25rem
					margin-bottom 0.25rem
					padding-right 1rem // to match selection dropdown

			&.editable-selection
				.editable-note-wrapper
					margin-top 0.25rem
					margin-bottom 0.25rem
					padding-right 1rem // to match selection dropdown

		.selection
			padding-right 1rem // room for saving icon

			&.faded
				color $faded-font

			&.has-droptimizer
				padding-left 2rem

			&.select-input
				.actioning-icon
					$vertical-center()
					right -1rem

				.dps-change
					$vertical-center()
					right 87%
					color $red
					text-align right
					font-size 0.85rem

					&.gain
						color $green

			.menu-widget .content
				max-height 20rem

	.characters
		margin -0.2rem

		.character-box
			display inline-block
			user-select none
			color $black-full
			width 6.65rem
			height 2.85rem
			font-size 0.875rem
			cursor pointer
			margin 0.2rem
			vertical-align top

			.box-content
				height 100%
				padding 0.15rem 0.1rem 0.25rem
				display: flex
				overflow hidden
				flex-direction column
				border-radius $border-radius
				opacity 0.8

			.char-name
				text-align: center
				overflow hidden
				white-space: nowrap

				.dps
					position: relative
					bottom 0.5px
					font-size 0.75rem
					padding-right 2px

			.bottom-row
				flex 1 1 0%
				display: flex
				align-items: center
				justify-content: center

				.want-label
					white-space: nowrap
					overflow hidden

				.note-icon
					margin-right 2px
					margin-top 1px
