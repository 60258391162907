.roster-view-new .section-toggles
	display flex

	.toggle
		cursor pointer
		width 2.5rem
		height @width
		border-radius $border-radius
		color $primary
		cursor pointer

		& + .toggle
			margin-left 0.5rem

		&.active
			color $primary-font
			background-color $primary-faded

		svg
			$center()
			font-size 1.5rem
