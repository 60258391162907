.new-user-billing-view
	margin 0 auto
	max-width 65rem
	padding-bottom 8rem !important

.new-user-billing-view .plan-selection
	.feature-table-wrapper
		max-width 36rem
		margin 0 auto
		margin-top 5rem

		.perks-heading
			text-align center
			font-size 1.15rem

		.billing-feature-table
			margin-top 3rem

	.plans
		grid-template-columns repeat(2, 20rem)
		display grid
		gap 1rem 2rem
		justify-content center

	.currency-text
		color $faded-font
		margin-top 1rem
		text-align center
		font-size 0.85rem

	.plan
		display inline-block
		background-color $foreground-light
		width 20rem
		border 1px solid $card-border
		padding 1rem 1.5rem 1.5rem 1.5rem
		border-radius $border-radius
		text-align: center

		.title
			font-size 1.25rem
			margin-bottom 0.25rem

		.description
			margin-bottom 1.5rem
			color $faded-font

		.price-section
			display: flex
			align-items: center
			justify-content: center

			.currency
				color $faded-font
				font-size: 0.85rem
				margin-right 0.5rem

			.price
				font-size 1.15rem
				margin-left 1rem

			.period
				color $faded-font
				font-size 0.85rem
				margin-left 0.5rem

.new-user-billing-view .existing-sub
	width 95%
	max-width 36rem
	margin 0 auto

	.buffed-guilds
		margin-bottom 10rem

		.buffs-info
			.buff-usage
				margin-bottom 2rem
				text-align: center
				color $faded-font

				.stats
					$bold()

		.no-buffed-guilds
			margin-bottom 2rem
			border 2px solid $yellow
			background-color alpha($yellow, 10%)
			border-radius: $border-radius
			padding 1rem 1.5rem 1rem 0.75rem
			display: flex

			i
				color $yellow
				margin-right 1rem
				font-size 1.5rem

		.buff-item
			position: relative
			display flex
			justify-content space-between
			align-items: center
			padding 1rem 1rem
			border 1px solid $card-border
			border-radius $border-radius
			background-color $foreground
			margin-top 1.5rem

			.buff-tag
				position: absolute
				top 0
				transform: translateY(-50%)
				border-radius $border-radius
				background-color $primary
				color $darker-light
				padding 0.15rem 0.35rem
				text-transform: uppercase
				font-size 0.75rem
				letter-spacing: 0.5px

			.guild-details
				.name
					$bold()
					font-size 1.15rem

				.realm
					color $half-light
					margin-left 1rem

	.manage-billing
		margin-bottom 5rem

		.content
			display flex

			.button-group
				margin-top 0
				border-left 1px solid $pale-light
				margin-left 1rem
				padding-left 2rem

				.button:first-child
					margin-top 0

				.cancel-button
					margin-top 1rem

		.fields
			flex 1 1 0%

			.manage-field
				display: flex

				& + .manage-field
					margin-top 0.5rem

				.field-name
					flex 0 0 8rem
					color $faded-font

				.field-value
					flex 1 1 0%

.modal-content.change-plan-modal
	max-width 38rem !important

	.description
		display flex
		justify-content: space-evenly

	.change-arrow
		align-self: center
		margin-right 2rem
		margin-left @margin-right
		font-size 1.5rem

	.section
		display flex
		flex-direction: column
		flex 1 0 0%

		.section-heading
			text-align left
			margin-bottom 0.5rem

		.field
			display: flex
			text-align: left

			& + .manage-field
				margin-top 0.5rem

			.field-name
				flex 0 0 8rem
				color $faded-font

			.field-value
				flex 1 1 0%

.billing-feature-table
	.feature
		display flex
		align-items center

		&:nth-child(even)
			flex-direction row-reverse

			.imagery
				margin-right 0 !important
				margin-left 2rem

		& + .feature
			margin-top 2rem

		.imagery
			width 8.5rem
			height @width
			flex-shrink 0
			margin-right 2rem

		.info
			flex 1 1 0%

			.name
				$bold()
				margin-bottom 0.35rem
				display flex
				align-items: center

				.beta
					border-radius $border-radius
					background-color $primary
					color $darker-light
					padding 0.15rem 0.35rem
					text-transform: uppercase
					font-size 0.75rem
					letter-spacing: 0.5px
					margin-right 0.5rem

				.coming-soon
					display inline-block
					font-weight normal
					color $faded-font
					margin-left 0.5rem

			.description
				color $faded-font

.billing-required-prompt
	max-width 30rem
	margin 0 auto

	& + *
		margin-top 3rem

	.billing-link
		display table
		margin 0 auto

	.billing-icon
		display block
		text-align center
		margin 0 auto 2rem
		width 13rem

	.billing-description
		text-align center
		margin-bottom 2rem

.modal-container .modal-content.select-sponsorships-modal
	max-width 35rem

	.buffs-info
		margin-bottom 2rem

		.buff-usage
			margin-bottom 2rem
			text-align: center
			color $faded-font

			.stats
				$bold()

		.buffs
			width 95%
			max-width 36rem
			margin 0 auto

	.buff-item
		position: relative
		display flex
		justify-content space-between
		align-items: center
		padding 1rem 1rem
		border 1px solid $card-border
		border-radius $border-radius
		background-color $foreground
		margin-top 1.5rem

		.buff-tag
			position: absolute
			top 0
			transform: translateY(-50%)
			border-radius $border-radius
			background-color $primary
			color $darker-light
			padding 0.15rem 0.35rem
			text-transform: uppercase
			font-size 0.75rem
			letter-spacing: 0.5px

		.guild-details
			.name
				$bold()
				font-size 1.15rem

			.realm
				color $half-light
				margin-left 1rem
