$section-assignment-controls-border = $light-200
$section-assignment-controls-px = 1rem

.roster-view-new .section-assignment-controls
	border 1px solid $section-assignment-controls-border
	border-radius $border-radius

	.tabs
		display flex

		.tab
			flex 1 1 0%
			text-align center
			padding 0.35rem
			cursor pointer
			border-bottom 1px solid $section-assignment-controls-border

			& + .tab
				border-left 1px solid $section-assignment-controls-border

			&.active
				background-color alpha($primary, 20%)
				border-bottom-color $primary

			img
				width 1.75rem
				height @width
				opacity $faded-opacity

	.tab-content
		padding-top 0.75rem

		.role-limit
			margin-bottom @padding-top
			padding-left $section-assignment-controls-px
			padding-right @padding-left

			.limited-banner
				color $red

			.role-limit-control
				display flex
				align-items center
				color $faded-font

				.help-icon
					position: relative
					top 2px
					margin-left 0.75rem
					font-size 18px
					color $grey

				.limit
					margin-left 0.5rem
					margin-right @margin-left
					width 3rem

					&.limited
						.input
							background-color alpha($red, $faded-opacity)

					.input
						text-align center
