.button-group
	display flex
	align-items center
	margin-top 1rem

	&.tiny-top-margin
		margin-top 0.35rem

	&.no-top-margin
		margin-top 0

	&.center
		justify-content center

	&.left
		justify-content flex-start

	&.right
		justify-content flex-end

	&.vertical
		flex-direction column

		.button
			margin-top 0.5rem
			margin-right 0

	.button
		display block
		margin-right 0.5rem

		&:last-child
			margin-right 0

		&.left
			margin-right auto

.button
	$font-family()
	$bold()
	user-select none
	position relative
	display table
	padding 0.5rem 1rem
	outline 0
	border none
	border-radius $small-border-radius
	color $super-faded-font
	text-align center
	text-decoration none
	text-shadow 0 1px 1px $pale-dark
	white-space nowrap
	font-style normal
	font-size 16px
	line-height 1.2
	cursor pointer
	background-color transparent

	&.normal-weight
		font-weight 500

	&:hover
	&:focus
		box-shadow inset 0 -2px rgba(0, 0, 0, 0.25)

	&:active
		box-shadow inset 0 2px 1px rgba(0, 0, 0, 0.2) !important

	&:after
		position absolute
		top 0
		right 0
		bottom 0
		left 0
		border-radius @border-radius
		content ''

	&.underlined,
		&&.underlined .ladda-label
			text-decoration underline

	&.faded
		opacity $faded-opacity

	&.right
		float right

	&.small
		padding 5px 10px
		font-size 14px

	&.medium
		padding 0.625rem 1.25rem
		border-radius $border-radius
		font-size 18px

	&.large
		$super-bold()
		padding 0.75rem 1.5rem
		border-radius $border-radius
		font-size 20px

	&.fluid
		width 100%

	&.green
		background-color $green
		color $primary-font-pop

	&.blue
		background-color $blue
		color $primary-font-pop

	&.red
		background-color $red
		color $primary-font-pop

	&.grey
		background-color $button-grey
		color $primary-font-pop

	&.primary
		// background-color $primary
		background $focus-box-linear-gradient-button
		color $primary-font-pop

	&.link
		text-shadow none

		&:active
		&:hover
		&:focus
			box-shadow none !important

	&.outline:not(.disabled)
		background-color transparent
		background transparent
		text-shadow none
		border 1px solid $grey-light

		&:after
			display none

		&:hover
		&:focus
			background-color alpha($red, 5%)
			box-shadow none

		&.red
			color $red
			border-color $red

		&.blue
			color $blue
			border-color $blue

		&.green
			color $green
			border-color $green

		&.grey
			color $light-500
			border-color $light-300

			&:hover
			&:focus
				background-color alpha($light-300, 8%)

	&.subtle
		text-shadow none
		color $primary-font

		&:hover
		&:focus
			box-shadow none

		&.primary
			background alpha($primary, 40%)

			&:hover
				background alpha($primary, 75%)

		&.grey
			background alpha($button-grey, 75%)

			&:hover
				background alpha($button-grey, 99%)

	&.disabled
		background-color $grey
		color $primary-font-pop
		opacity 0.35
		pointer-events none
