.roster-view-new .boss-view-section .roster-page-heading-row
	.right-group
		display flex
		align-items: center

		.view-selector
			margin-bottom 0
			margin-right 0.25rem
			margin-left 1rem
			width 12rem
