$cooldown-sheet-event-width = 16rem
$cooldown-sheet-time-width = 9rem
$cooldown-sheet-menu-width = 2.5rem
$cooldown-table-frame-border = $light-200
$cooldown-row-border = $light-100

$cooldown-background-colour = $foreground

.roster-view-new .boss-cooldown-view
	.sheet-is-loading
		text-align: center

.roster-view-new .boss-cooldown-view .cooldown-view-content
	margin-top 1.5rem

.roster-view-new .boss-cooldown-view .cooldown-view-content .cooldown-sheet-table
	min-height: 25rem
	position relative

	.col-marker
		position absolute
		top 0
		bottom 0
		width 2px
		background $cooldown-table-frame-border

		&.event
			left $cooldown-sheet-event-width

		&.time
			left $cooldown-sheet-event-width + $cooldown-sheet-time-width

	.col-event
		flex 0 0 $cooldown-sheet-event-width

	.col-time
		flex 0 0 $cooldown-sheet-time-width

	.col-row-menu
		flex 0 0 $cooldown-sheet-menu-width

	.col-actions
		flex 1 1 auto

	.add-event-container
		display flex
		padding 0.5rem 1rem 0.5rem 0
		position relative

		.prompt
			text-decoration: underline
			color $light-400
			padding-top 0.5rem
			padding-bottom @padding-top
			cursor pointer

			&:hover
				color $light-600

		.inline-editable-display
			padding-top 0.5rem
			padding-bottom @padding-top

	.event-name-select
		top 100%
		left -1rem
		margin-top -0.5rem

		.react-select-prefix__option
			display: flex
			align-items: center

		.event-name-select-option-image
			width 1.6rem
			height @width
			border-radius: $border-radius
			margin-right 0.5rem


	.headings
		display flex
		border-bottom 2px solid $cooldown-table-frame-border
		color $light-500
		text-transform: uppercase

		.col
			padding 0.35rem 1rem

			&.col-event
				padding-left 0

	.events
		.event-row
			position: relative
			display flex
			border-bottom 1px solid $cooldown-row-border

			&.dragged
				border-top 1px solid $primary
				border-color $primary
				background-color $cooldown-background-colour
				z-index: 1

				.event-drag-handle
					color $primary !important

			&.editor
				.event-name-inner
					cursor pointer

					.event-name-content
						cursor pointer !important

					.event-name-text
						border-bottom 1px solid $light-300

			.col
				padding 1.15rem 1rem

				&.col-event
					padding 0
					display: flex

					.event-drag-handle
						position relative
						padding 1.15rem 0.8rem
						margin-left -0.8rem
						cursor pointer
						touch-action: none
						font-size 1.25rem
						color $light-400
						margin-top 1px

						&:hover
							color $light-600

						&.with-event-icon
							top 2px

					.event-name
						display flex
						flex 1 1 auto
						align-items: flex-start

						.event-name-inner
							position: relative
							padding 1.15rem 1rem 1.15rem 0
							flex 1 1 auto

						.event-name-content
							display flex
							display flex
							align-items: center
							cursor auto

						.event-ability-icon
							width 1.5rem
							height @width
							margin-right 0.25rem

					.inline-editable
						padding 1.15rem 0
						padding-right 1rem
						flex 1 1 auto

						&.submitting
							color $light-400

				&.col-time
					&.submitting
						color $light-400

				&.col-actions
					display flex
					padding-top 0.5rem
					padding-bottom 0.5rem

				&.col-row-menu
					padding 0.5rem 0 0 0
					color $light-400

					&:hover
						color $light-700
