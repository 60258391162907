.rclc-view
	$roster-sizing()
	max-width 65rem
	padding-bottom 12rem

	.intro-section
		max-width 65ch
		margin 0 auto 2rem

		h1
			text-align center
			margin-bottom 3rem

.rclc-view .importer-container
	max-width 40rem
	margin 0 auto

	.import-field
		.input
			resize vertical
			min-height 10rem

.rclc-view .entries
	.no-rows
		margin 0 auto
		text-align center
		max-width 50ch

		.emoji
			font-size 3rem
			margin-bottom 1rem

		.button-group
			margin-top 3rem

.rclc-view .entry-rows
	margin-top 3rem
	margin-bottom 3rem

	.entry-row-wrapper
		padding 0
		margin 0 auto 1rem

	.entry-row
		position relative
		padding 0.85rem 1rem 1rem 1.5rem
		display flex
		justify-content space-between
		align-items center

		&:before
			position absolute
			width 4px
			left 0
			top -1px
			bottom 1px
			display block
			content ''

	.left
		width 12rem
		margin-right 3rem

		.item-info
			a
				display flex
				align-items center

				img
					width 2.5rem
					height @width
					border-radius $border-radius
					border 1px solid $epic-colour
					flex-shrink 0

					&.conduit
						border-color $common-colour

				.name
					$bold()
					letter-spacing 0.75px
					margin-left 0.75rem
					font-size 0.875rem

	.middle
		flex 1 1 0%

		.character-info
			display flex
			align-items center

			img
				border-radius $border-radius
				width 2.5rem
				height @width
				margin-right 0.65rem

			.details
				flex 1 0 0%

				.name
					$bold()
					font-size 1.15rem

				.secondary
					color $secondary-font
					font-size 0.875rem

	.right
		display flex
		flex-direction column
		width 15rem
		margin-left 2rem

		.selection-dropdown
			align-self flex-end
			width 100%

			&.faded
				color $faded-font

			.menu-widget .content
				max-height 20rem

			.actioning-icon
				$vertical-center()
				right -2rem;

		.original-selection
			text-align center
			color $secondary-font
			margin-bottom 0.5rem
			font-size 0.875rem
