.roster-view-new .roster-page-heading-details
	display flex
	align-items: flex-end
	line-height: 1

	.heading
		$bold()
		font-size 1.5rem
		color $secondary-font

	.subheading
		color $faded-font
		font-size: 0.875rem
		margin-left 0.75rem
		margin-bottom 1px


.roster-view-new .roster-page-heading-row
	display flex
	align-items: center
	justify-content: space-between
