.roster-view-new .boss-view-section
	background $foreground
	padding 1rem
	border-radius $border-radius

.roster-view-new .roster-boss-content
	margin-top 1.5rem
	display grid
	gap 2.5rem
	// grid-template-columns: 1fr minmax(24rem, 36rem)
	grid-template-columns: minmax(26rem, 48rem) 1fr

	&:not(.bossy)
		@media (max-width 1280px)
			grid-template-columns: minmax(26rem, 45rem) 1fr
			gap 1rem

		@media (max-width 1095px)
			grid-template-columns: 1fr
			gap 2.5rem

			.secondary-column
				max-width 50rem
				margin-left 1.25rem

	&.bossy
		grid-template-columns: minmax(17rem, 1fr) 2fr
		gap 0.5rem

		.secondary-column
			max-width 50rem

		.main-column
			& > .boss-image
				width 90%
				max-width 22rem
				display: block

	.secondary-column
		.secondary-section + .secondary-section
			margin-top 1.5rem
