.roster-view-new .boss-selector-section
	margin-top 1rem
	display grid
	gap: 0.5rem
	grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr))

	.boss-selector-tile
		border 2px solid transparent
		border-radius $border-radius
		cursor pointer

		&.selected
			border-color $primary
			background-color $light-dark

		&.unselected
			img
				opacity 0.85

			&:hover
				img
					opacity: 0.95

		img
			width 100%
			display block
