.roster-list-view
	padding 8vh 2rem 2rem

	.heading
		text-align center
		width 30rem
		max-width 95%
		margin 0 auto 3rem

		h1
			$super-bold()

			p
				width 25rem
				margin-left auto
				margin-right auto

	.browser-item
		display flex
		align-items center
		justify-content space-between
		width 95%
		max-width 30rem
		padding 1.5rem 1.65rem
		margin 0 auto 0.75rem
		border 1px solid $card-border
		border-radius $border-radius
		background $focus-box-linear-gradient-hover

		&:hover
			background $focus-box-linear-gradient-hover-bright

		.name
			$super-bold()
			font-size 1.125rem

		.details
			text-align right

			.instance-name
				color $secondary-font

			.difficulty
				color $faded-font
				font-size 0.875rem
				margin-top 0.35rem
