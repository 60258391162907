$switch-height = 24px
$switch-padding = 3px
$switch-height-minus-padding = $switch-height - ($switch-padding * 2)

.switch-field
	display flex
	flex-direction column

	&.with-top-margin
		margin-top 0.5rem

	.switch-top
		display flex
		align-items center
		justify-content  space-between

	.switch-bottom
		margin-top 0.35rem
		font-size 0.875rem
		color $secondary-font

	.label
		$bold()
		line-height 1.5

	.label + .switch-button
		margin-left 1rem

.switch-button
	display flex
	align-items center
	cursor pointer
	user-select none

	input
		display none

	label
		position relative
		outline 0
		display block
		width 2.625rem
		height $switch-height
		padding $switch-padding
		cursor pointer
		background-color $input-background + 3%
		border-radius @height * 0.5
		transition all 400ms ease
		font-size 0.9rem
		line-height $switch-height-minus-padding - 1
		color $primary-font
		pointer-events none

		&:before
			$bold()
			position absolute
			content attr(data-off-text)
			right $switch-height-minus-padding * 0.5

		&:after
			position relative
			display block
			content ' '
			width $switch-height-minus-padding
			height @width
			left 0
			border-radius 50%
			background-color $primary-font-pop
			transition all 200ms ease

	&.switched
		label
			background-color $primary

			&:before
				content attr(data-on-text)
				right initial
				left $switch-height-minus-padding * 0.5

			&:after
				left 100%
				transform translateX(-100%)

	&.disabled
		pointer-events none

		label
			background-color $faded-font
