.modal-container
	$overflow-auto()
	contain strict
	position fixed
	top 0
	bottom 0
	left 0
	right 0
	z-index 50

	.modal-overlay
		z-index 1
		display table
		width 100%
		height 100%
		background-color rgba(0, 0, 0, 0.75)
		transform translateZ(0)
		backdrop-filter: blur(4px)

	.modal-box
		display: table-cell
		padding 4rem
		backface-visibility: hidden
		vertical-align: middle

	.modal-content
		position: relative
		width 100%
		max-width 27rem
		margin auto
		background-color $background
		border-radius $border-radius
		text-align center

		&.normal-alignment
			text-align: left

		&.no-overflow
			overflow visible

		.heading
			$super-bold()
			position relative
			color $primary-font-pop
			text-transform uppercase
			padding 1.75rem 1.25rem 1.35rem
			letter-spacing 0.35px

		.description
			$bold()
			padding 0 1.25rem 1.5rem
			font-size 0.9375rem

			&.left
				text-align left

			strong
				$super-bold()

		.inputs
			text-align left
			padding 0 1.25rem 1.5rem

		.actions
			background-color $foreground
			display flex
			justify-content space-between
			padding 1.15rem 1.25rem

			.button
				$bold()
				width 48.5%
				display flex
				align-items center
				justify-content center
				height 3.125rem
