$tooltip-colour = $full-dark

.tooltip
	position relative

	&:before
	&:after
		position absolute
		transform translate3d(0, 0, 0)
		z-index 1000
		visibility hidden
		opacity 0
		transition 0.3s ease
		transition-delay 0
		pointer-events none

	&:hover:before
	&:hover:after
		visibility visible
		opacity 1
		transition-delay 100ms

	&:before
		position absolute
		z-index 1001
		border 6px solid transparent
		background-color transparent
		content ''

	&:after
		$font-family()
		$bold()
		padding 8px 10px
		border-radius $small-border-radius
		background-color $tooltip-colour
		color $white
		white-space nowrap
		font-size 0.825rem
		line-height @font-size

	&[data-hint]:after
		content attr(data-hint)

	&.delayed
		&:hover:before
		&:hover:after
			transition-delay 200ms


.tooltip-top-left:before
	border-top-color $tooltip-colour

.tooltip-top-right:before
	border-top-color $tooltip-colour

.tooltip-top:before,
.tooltip-top-closer::before
	border-top-color $tooltip-colour

.tooltip-bottom-left:before
	border-bottom-color $tooltip-colour

.tooltip-bottom-right:before
	border-bottom-color $tooltip-colour

.tooltip-bottom:before
	border-bottom-color $tooltip-colour

.tooltip-left:before
	border-left-color $tooltip-colour

.tooltip-right:before
	border-right-color $tooltip-colour


.tooltip-small,
.tooltip-medium,
.tooltip-large
	&:after
		white-space normal
		line-height 1.2
		word-wrap break-word

.tooltip-small:after
	width 5rem

.tooltip-medium:after
	width 9.375rem

.tooltip-large:after
	width 18.75rem

.tooltip-user-content:after
	white-space: pre-wrap



.tooltip-top
	&:before
	&:after
		bottom 100%
		left 50%

	&:before
		left calc(50% - 6px)
		margin-bottom -11px

	&:after
		transform translateX(-50%)

	&:hover:before
		transform translateY(-8px)

	&:hover:after
		transform translate(-50%, -8px)


.tooltip-top-closer
	&:before
	&:after
		bottom 100%
		left 50%

	&:before
		left calc(50% - 6px)
		margin-bottom -11px

	&:after
		transform translateX(-50%)

	&:hover:before
		transform translateY(-0px)

	&:hover:after
		transform translate(-50%, -0px)


.tooltip-bottom
	&:before
	&:after
		top 100%
		left 50%

	&:before
		left calc(50% - 6px)
		margin-top -11px

	&:after
		transform translateX(-50%)

	&:hover:before
		transform translateY(8px)

	&:hover:after
		transform translate(-50%, 8px)


.tooltip-right
	&:before
	&:after
		bottom 50%
		left 100%

	&:before
		margin-bottom -6px
		margin-left -11px

	&:after
		transform translateY(50%)

	&:hover:before
		transform translateX(8px)

	&:hover:after
		transform translateX(8px) translateY(50%)


.tooltip-left
	&:before
	&:after
		right 100%
		bottom 50%

	&:before
		margin-right -11px
		margin-bottom -6px

	&:after
		transform translateY(50%)

	&:hover:before
		transform translateX(-8px)

	&:hover:after
		transform translateX(-8px) translateY(50%)


.tooltip-top-left
	&:before
	&:after
		bottom 100%
		left 50%

	&:before
		left calc(50% - 6px)
		margin-bottom -11px

	&:after
		transform translateX(-100%)
		margin-left 12px

	&:hover:before
		transform translateY(-8px)

	&:hover:after
		transform translateX(-100%) translateY(-8px)


.tooltip-top-right
	&:before
	&:after
		bottom 100%
		left 50%

	&:before
		left calc(50% - 6px)
		margin-bottom -11px

	&:after
		transform translateX(0)
		margin-left -12px

	&:hover:before
		transform translateY(-8px)

	&:hover:after
		transform translateY(-8px)


.tooltip-bottom-left
	&:before
	&:after
		top 100%
		left 50%

	&:before
		left calc(50% - 6px)
		margin-top -11px

	&:after
		transform translateX(-100%)
		margin-left 12px

	&:hover:before
		transform translateY(8px)

	&:hover:after
		transform translateX(-100%) translateY(8px)


.tooltip-bottom-right
	&:before
	&:after
		top 100%
		left 50%

	&:before
		left calc(50% - 6px)
		margin-top -11px

	&:after
		transform translateX(0)
		margin-left -12px

	&:hover:before
		transform translateY(8px)

	&:hover:after
		transform translateY(8px)
