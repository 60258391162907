@keyframes action-icon-spin
	0%
		transform rotate(0deg)

	100%
		transform rotate(359deg)

@keyframes action-icon-fade
	0%
		opacity 1

	100%
		opacity 0

.actioning-icon
	position absolute
	padding 0.5rem

	i
		display inline-block // needed for the icon to spin

	.neutral
		opacity 0

	.load
		animation action-icon-spin 2s infinite linear

	.success
		color $green
		animation-name action-icon-fade
		animation-duration 250ms
		animation-timing-function cubic-bezier(0.4, 0, 0.2, 1)
		animation-delay 4750ms

	.error
		color $red
