@import "icon-variables"

@font-face
  font-family 'linearicons'
  src url(font-path + '/linearicons.woff?krvr4m') format('woff'), url(font-path + '/linearicons.ttf?krvr4m') format('truetype'), url(font-path + '/linearicons.svg?krvr4m#linearicons') format('svg')
  font-weight normal
  font-style normal

.lnr
  font-family 'linearicons' !important
  speak none
  font-style normal
  font-weight normal
  font-variant normal
  text-transform none
  line-height 1

  /* Better Font Rendering =========== */
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale

.lnr-home
  &:before
    content lnr-home
.lnr-home2
  &:before
    content lnr-home2
.lnr-home3
  &:before
    content lnr-home3
.lnr-home4
  &:before
    content lnr-home4
.lnr-home5
  &:before
    content lnr-home5
.lnr-home6
  &:before
    content lnr-home6
.lnr-bathtub
  &:before
    content lnr-bathtub
.lnr-toothbrush
  &:before
    content lnr-toothbrush
.lnr-bed
  &:before
    content lnr-bed
.lnr-couch
  &:before
    content lnr-couch
.lnr-chair
  &:before
    content lnr-chair
.lnr-city
  &:before
    content lnr-city
.lnr-apartment
  &:before
    content lnr-apartment
.lnr-pencil
  &:before
    content lnr-pencil
.lnr-pencil2
  &:before
    content lnr-pencil2
.lnr-pen
  &:before
    content lnr-pen
.lnr-pencil3
  &:before
    content lnr-pencil3
.lnr-eraser
  &:before
    content lnr-eraser
.lnr-pencil4
  &:before
    content lnr-pencil4
.lnr-pencil5
  &:before
    content lnr-pencil5
.lnr-feather
  &:before
    content lnr-feather
.lnr-feather2
  &:before
    content lnr-feather2
.lnr-feather3
  &:before
    content lnr-feather3
.lnr-pen2
  &:before
    content lnr-pen2
.lnr-pen-add
  &:before
    content lnr-pen-add
.lnr-pen-remove
  &:before
    content lnr-pen-remove
.lnr-vector
  &:before
    content lnr-vector
.lnr-pen3
  &:before
    content lnr-pen3
.lnr-blog
  &:before
    content lnr-blog
.lnr-brush
  &:before
    content lnr-brush
.lnr-brush2
  &:before
    content lnr-brush2
.lnr-spray
  &:before
    content lnr-spray
.lnr-paint-roller
  &:before
    content lnr-paint-roller
.lnr-stamp
  &:before
    content lnr-stamp
.lnr-tape
  &:before
    content lnr-tape
.lnr-desk-tape
  &:before
    content lnr-desk-tape
.lnr-texture
  &:before
    content lnr-texture
.lnr-eye-dropper
  &:before
    content lnr-eye-dropper
.lnr-palette
  &:before
    content lnr-palette
.lnr-color-sampler
  &:before
    content lnr-color-sampler
.lnr-bucket
  &:before
    content lnr-bucket
.lnr-gradient
  &:before
    content lnr-gradient
.lnr-gradient2
  &:before
    content lnr-gradient2
.lnr-magic-wand
  &:before
    content lnr-magic-wand
.lnr-magnet
  &:before
    content lnr-magnet
.lnr-pencil-ruler
  &:before
    content lnr-pencil-ruler
.lnr-pencil-ruler2
  &:before
    content lnr-pencil-ruler2
.lnr-compass
  &:before
    content lnr-compass
.lnr-aim
  &:before
    content lnr-aim
.lnr-gun
  &:before
    content lnr-gun
.lnr-bottle
  &:before
    content lnr-bottle
.lnr-drop
  &:before
    content lnr-drop
.lnr-drop-crossed
  &:before
    content lnr-drop-crossed
.lnr-drop2
  &:before
    content lnr-drop2
.lnr-snow
  &:before
    content lnr-snow
.lnr-snow2
  &:before
    content lnr-snow2
.lnr-fire
  &:before
    content lnr-fire
.lnr-lighter
  &:before
    content lnr-lighter
.lnr-knife
  &:before
    content lnr-knife
.lnr-dagger
  &:before
    content lnr-dagger
.lnr-tissue
  &:before
    content lnr-tissue
.lnr-toilet-paper
  &:before
    content lnr-toilet-paper
.lnr-poop
  &:before
    content lnr-poop
.lnr-umbrella
  &:before
    content lnr-umbrella
.lnr-umbrella2
  &:before
    content lnr-umbrella2
.lnr-rain
  &:before
    content lnr-rain
.lnr-tornado
  &:before
    content lnr-tornado
.lnr-wind
  &:before
    content lnr-wind
.lnr-fan
  &:before
    content lnr-fan
.lnr-contrast
  &:before
    content lnr-contrast
.lnr-sun-small
  &:before
    content lnr-sun-small
.lnr-sun
  &:before
    content lnr-sun
.lnr-sun2
  &:before
    content lnr-sun2
.lnr-moon
  &:before
    content lnr-moon
.lnr-cloud
  &:before
    content lnr-cloud
.lnr-cloud-upload
  &:before
    content lnr-cloud-upload
.lnr-cloud-download
  &:before
    content lnr-cloud-download
.lnr-cloud-rain
  &:before
    content lnr-cloud-rain
.lnr-cloud-hailstones
  &:before
    content lnr-cloud-hailstones
.lnr-cloud-snow
  &:before
    content lnr-cloud-snow
.lnr-cloud-windy
  &:before
    content lnr-cloud-windy
.lnr-sun-wind
  &:before
    content lnr-sun-wind
.lnr-cloud-fog
  &:before
    content lnr-cloud-fog
.lnr-cloud-sun
  &:before
    content lnr-cloud-sun
.lnr-cloud-lightning
  &:before
    content lnr-cloud-lightning
.lnr-cloud-sync
  &:before
    content lnr-cloud-sync
.lnr-cloud-lock
  &:before
    content lnr-cloud-lock
.lnr-cloud-gear
  &:before
    content lnr-cloud-gear
.lnr-cloud-alert
  &:before
    content lnr-cloud-alert
.lnr-cloud-check
  &:before
    content lnr-cloud-check
.lnr-cloud-cross
  &:before
    content lnr-cloud-cross
.lnr-cloud-crossed
  &:before
    content lnr-cloud-crossed
.lnr-cloud-database
  &:before
    content lnr-cloud-database
.lnr-database
  &:before
    content lnr-database
.lnr-database-add
  &:before
    content lnr-database-add
.lnr-database-remove
  &:before
    content lnr-database-remove
.lnr-database-lock
  &:before
    content lnr-database-lock
.lnr-database-refresh
  &:before
    content lnr-database-refresh
.lnr-database-check
  &:before
    content lnr-database-check
.lnr-database-history
  &:before
    content lnr-database-history
.lnr-database-upload
  &:before
    content lnr-database-upload
.lnr-database-download
  &:before
    content lnr-database-download
.lnr-server
  &:before
    content lnr-server
.lnr-shield
  &:before
    content lnr-shield
.lnr-shield-check
  &:before
    content lnr-shield-check
.lnr-shield-alert
  &:before
    content lnr-shield-alert
.lnr-shield-cross
  &:before
    content lnr-shield-cross
.lnr-lock
  &:before
    content lnr-lock
.lnr-rotation-lock
  &:before
    content lnr-rotation-lock
.lnr-unlock
  &:before
    content lnr-unlock
.lnr-key
  &:before
    content lnr-key
.lnr-key-hole
  &:before
    content lnr-key-hole
.lnr-toggle-off
  &:before
    content lnr-toggle-off
.lnr-toggle-on
  &:before
    content lnr-toggle-on
.lnr-cog
  &:before
    content lnr-cog
.lnr-cog2
  &:before
    content lnr-cog2
.lnr-wrench
  &:before
    content lnr-wrench
.lnr-screwdriver
  &:before
    content lnr-screwdriver
.lnr-hammer-wrench
  &:before
    content lnr-hammer-wrench
.lnr-hammer
  &:before
    content lnr-hammer
.lnr-saw
  &:before
    content lnr-saw
.lnr-axe
  &:before
    content lnr-axe
.lnr-axe2
  &:before
    content lnr-axe2
.lnr-shovel
  &:before
    content lnr-shovel
.lnr-pickaxe
  &:before
    content lnr-pickaxe
.lnr-factory
  &:before
    content lnr-factory
.lnr-factory2
  &:before
    content lnr-factory2
.lnr-recycle
  &:before
    content lnr-recycle
.lnr-trash
  &:before
    content lnr-trash
.lnr-trash2
  &:before
    content lnr-trash2
.lnr-trash3
  &:before
    content lnr-trash3
.lnr-broom
  &:before
    content lnr-broom
.lnr-game
  &:before
    content lnr-game
.lnr-gamepad
  &:before
    content lnr-gamepad
.lnr-joystick
  &:before
    content lnr-joystick
.lnr-dice
  &:before
    content lnr-dice
.lnr-spades
  &:before
    content lnr-spades
.lnr-diamonds
  &:before
    content lnr-diamonds
.lnr-clubs
  &:before
    content lnr-clubs
.lnr-hearts
  &:before
    content lnr-hearts
.lnr-heart
  &:before
    content lnr-heart
.lnr-star
  &:before
    content lnr-star
.lnr-star-half
  &:before
    content lnr-star-half
.lnr-star-empty
  &:before
    content lnr-star-empty
.lnr-flag
  &:before
    content lnr-flag
.lnr-flag2
  &:before
    content lnr-flag2
.lnr-flag3
  &:before
    content lnr-flag3
.lnr-mailbox-full
  &:before
    content lnr-mailbox-full
.lnr-mailbox-empty
  &:before
    content lnr-mailbox-empty
.lnr-at-sign
  &:before
    content lnr-at-sign
.lnr-envelope
  &:before
    content lnr-envelope
.lnr-envelope-open
  &:before
    content lnr-envelope-open
.lnr-paperclip
  &:before
    content lnr-paperclip
.lnr-paper-plane
  &:before
    content lnr-paper-plane
.lnr-reply
  &:before
    content lnr-reply
.lnr-reply-all
  &:before
    content lnr-reply-all
.lnr-inbox
  &:before
    content lnr-inbox
.lnr-inbox2
  &:before
    content lnr-inbox2
.lnr-outbox
  &:before
    content lnr-outbox
.lnr-box
  &:before
    content lnr-box
.lnr-archive
  &:before
    content lnr-archive
.lnr-archive2
  &:before
    content lnr-archive2
.lnr-drawers
  &:before
    content lnr-drawers
.lnr-drawers2
  &:before
    content lnr-drawers2
.lnr-drawers3
  &:before
    content lnr-drawers3
.lnr-eye
  &:before
    content lnr-eye
.lnr-eye-crossed
  &:before
    content lnr-eye-crossed
.lnr-eye-plus
  &:before
    content lnr-eye-plus
.lnr-eye-minus
  &:before
    content lnr-eye-minus
.lnr-binoculars
  &:before
    content lnr-binoculars
.lnr-binoculars2
  &:before
    content lnr-binoculars2
.lnr-hdd
  &:before
    content lnr-hdd
.lnr-hdd-down
  &:before
    content lnr-hdd-down
.lnr-hdd-up
  &:before
    content lnr-hdd-up
.lnr-floppy-disk
  &:before
    content lnr-floppy-disk
.lnr-disc
  &:before
    content lnr-disc
.lnr-tape2
  &:before
    content lnr-tape2
.lnr-printer
  &:before
    content lnr-printer
.lnr-shredder
  &:before
    content lnr-shredder
.lnr-file-empty
  &:before
    content lnr-file-empty
.lnr-file-add
  &:before
    content lnr-file-add
.lnr-file-check
  &:before
    content lnr-file-check
.lnr-file-lock
  &:before
    content lnr-file-lock
.lnr-files
  &:before
    content lnr-files
.lnr-copy
  &:before
    content lnr-copy
.lnr-compare
  &:before
    content lnr-compare
.lnr-folder
  &:before
    content lnr-folder
.lnr-folder-search
  &:before
    content lnr-folder-search
.lnr-folder-plus
  &:before
    content lnr-folder-plus
.lnr-folder-minus
  &:before
    content lnr-folder-minus
.lnr-folder-download
  &:before
    content lnr-folder-download
.lnr-folder-upload
  &:before
    content lnr-folder-upload
.lnr-folder-star
  &:before
    content lnr-folder-star
.lnr-folder-heart
  &:before
    content lnr-folder-heart
.lnr-folder-user
  &:before
    content lnr-folder-user
.lnr-folder-shared
  &:before
    content lnr-folder-shared
.lnr-folder-music
  &:before
    content lnr-folder-music
.lnr-folder-picture
  &:before
    content lnr-folder-picture
.lnr-folder-film
  &:before
    content lnr-folder-film
.lnr-scissors
  &:before
    content lnr-scissors
.lnr-paste
  &:before
    content lnr-paste
.lnr-clipboard-empty
  &:before
    content lnr-clipboard-empty
.lnr-clipboard-pencil
  &:before
    content lnr-clipboard-pencil
.lnr-clipboard-text
  &:before
    content lnr-clipboard-text
.lnr-clipboard-check
  &:before
    content lnr-clipboard-check
.lnr-clipboard-down
  &:before
    content lnr-clipboard-down
.lnr-clipboard-left
  &:before
    content lnr-clipboard-left
.lnr-clipboard-alert
  &:before
    content lnr-clipboard-alert
.lnr-clipboard-user
  &:before
    content lnr-clipboard-user
.lnr-register
  &:before
    content lnr-register
.lnr-enter
  &:before
    content lnr-enter
.lnr-exit
  &:before
    content lnr-exit
.lnr-papers
  &:before
    content lnr-papers
.lnr-news
  &:before
    content lnr-news
.lnr-reading
  &:before
    content lnr-reading
.lnr-typewriter
  &:before
    content lnr-typewriter
.lnr-document
  &:before
    content lnr-document
.lnr-document2
  &:before
    content lnr-document2
.lnr-graduation-hat
  &:before
    content lnr-graduation-hat
.lnr-license
  &:before
    content lnr-license
.lnr-license2
  &:before
    content lnr-license2
.lnr-medal-empty
  &:before
    content lnr-medal-empty
.lnr-medal-first
  &:before
    content lnr-medal-first
.lnr-medal-second
  &:before
    content lnr-medal-second
.lnr-medal-third
  &:before
    content lnr-medal-third
.lnr-podium
  &:before
    content lnr-podium
.lnr-trophy
  &:before
    content lnr-trophy
.lnr-trophy2
  &:before
    content lnr-trophy2
.lnr-music-note
  &:before
    content lnr-music-note
.lnr-music-note2
  &:before
    content lnr-music-note2
.lnr-music-note3
  &:before
    content lnr-music-note3
.lnr-playlist
  &:before
    content lnr-playlist
.lnr-playlist-add
  &:before
    content lnr-playlist-add
.lnr-guitar
  &:before
    content lnr-guitar
.lnr-trumpet
  &:before
    content lnr-trumpet
.lnr-album
  &:before
    content lnr-album
.lnr-shuffle
  &:before
    content lnr-shuffle
.lnr-repeat-one
  &:before
    content lnr-repeat-one
.lnr-repeat
  &:before
    content lnr-repeat
.lnr-headphones
  &:before
    content lnr-headphones
.lnr-headset
  &:before
    content lnr-headset
.lnr-loudspeaker
  &:before
    content lnr-loudspeaker
.lnr-equalizer
  &:before
    content lnr-equalizer
.lnr-theater
  &:before
    content lnr-theater
.lnr-3d-glasses
  &:before
    content lnr-3d-glasses
.lnr-ticket
  &:before
    content lnr-ticket
.lnr-presentation
  &:before
    content lnr-presentation
.lnr-play
  &:before
    content lnr-play
.lnr-film-play
  &:before
    content lnr-film-play
.lnr-clapboard-play
  &:before
    content lnr-clapboard-play
.lnr-media
  &:before
    content lnr-media
.lnr-film
  &:before
    content lnr-film
.lnr-film2
  &:before
    content lnr-film2
.lnr-surveillance
  &:before
    content lnr-surveillance
.lnr-surveillance2
  &:before
    content lnr-surveillance2
.lnr-camera
  &:before
    content lnr-camera
.lnr-camera-crossed
  &:before
    content lnr-camera-crossed
.lnr-camera-play
  &:before
    content lnr-camera-play
.lnr-time-lapse
  &:before
    content lnr-time-lapse
.lnr-record
  &:before
    content lnr-record
.lnr-camera2
  &:before
    content lnr-camera2
.lnr-camera-flip
  &:before
    content lnr-camera-flip
.lnr-panorama
  &:before
    content lnr-panorama
.lnr-time-lapse2
  &:before
    content lnr-time-lapse2
.lnr-shutter
  &:before
    content lnr-shutter
.lnr-shutter2
  &:before
    content lnr-shutter2
.lnr-face-detection
  &:before
    content lnr-face-detection
.lnr-flare
  &:before
    content lnr-flare
.lnr-convex
  &:before
    content lnr-convex
.lnr-concave
  &:before
    content lnr-concave
.lnr-picture
  &:before
    content lnr-picture
.lnr-picture2
  &:before
    content lnr-picture2
.lnr-picture3
  &:before
    content lnr-picture3
.lnr-pictures
  &:before
    content lnr-pictures
.lnr-book
  &:before
    content lnr-book
.lnr-audio-book
  &:before
    content lnr-audio-book
.lnr-book2
  &:before
    content lnr-book2
.lnr-bookmark
  &:before
    content lnr-bookmark
.lnr-bookmark2
  &:before
    content lnr-bookmark2
.lnr-label
  &:before
    content lnr-label
.lnr-library
  &:before
    content lnr-library
.lnr-library2
  &:before
    content lnr-library2
.lnr-contacts
  &:before
    content lnr-contacts
.lnr-profile
  &:before
    content lnr-profile
.lnr-portrait
  &:before
    content lnr-portrait
.lnr-portrait2
  &:before
    content lnr-portrait2
.lnr-user
  &:before
    content lnr-user
.lnr-user-plus
  &:before
    content lnr-user-plus
.lnr-user-minus
  &:before
    content lnr-user-minus
.lnr-user-lock
  &:before
    content lnr-user-lock
.lnr-users
  &:before
    content lnr-users
.lnr-users2
  &:before
    content lnr-users2
.lnr-users-plus
  &:before
    content lnr-users-plus
.lnr-users-minus
  &:before
    content lnr-users-minus
.lnr-group-work
  &:before
    content lnr-group-work
.lnr-woman
  &:before
    content lnr-woman
.lnr-man
  &:before
    content lnr-man
.lnr-baby
  &:before
    content lnr-baby
.lnr-baby2
  &:before
    content lnr-baby2
.lnr-baby3
  &:before
    content lnr-baby3
.lnr-baby-bottle
  &:before
    content lnr-baby-bottle
.lnr-walk
  &:before
    content lnr-walk
.lnr-hand-waving
  &:before
    content lnr-hand-waving
.lnr-jump
  &:before
    content lnr-jump
.lnr-run
  &:before
    content lnr-run
.lnr-woman2
  &:before
    content lnr-woman2
.lnr-man2
  &:before
    content lnr-man2
.lnr-man-woman
  &:before
    content lnr-man-woman
.lnr-height
  &:before
    content lnr-height
.lnr-weight
  &:before
    content lnr-weight
.lnr-scale
  &:before
    content lnr-scale
.lnr-button
  &:before
    content lnr-button
.lnr-bow-tie
  &:before
    content lnr-bow-tie
.lnr-tie
  &:before
    content lnr-tie
.lnr-socks
  &:before
    content lnr-socks
.lnr-shoe
  &:before
    content lnr-shoe
.lnr-shoes
  &:before
    content lnr-shoes
.lnr-hat
  &:before
    content lnr-hat
.lnr-pants
  &:before
    content lnr-pants
.lnr-shorts
  &:before
    content lnr-shorts
.lnr-flip-flops
  &:before
    content lnr-flip-flops
.lnr-shirt
  &:before
    content lnr-shirt
.lnr-hanger
  &:before
    content lnr-hanger
.lnr-laundry
  &:before
    content lnr-laundry
.lnr-store
  &:before
    content lnr-store
.lnr-haircut
  &:before
    content lnr-haircut
.lnr-store-24
  &:before
    content lnr-store-24
.lnr-barcode
  &:before
    content lnr-barcode
.lnr-barcode2
  &:before
    content lnr-barcode2
.lnr-barcode3
  &:before
    content lnr-barcode3
.lnr-cashier
  &:before
    content lnr-cashier
.lnr-bag
  &:before
    content lnr-bag
.lnr-bag2
  &:before
    content lnr-bag2
.lnr-cart
  &:before
    content lnr-cart
.lnr-cart-empty
  &:before
    content lnr-cart-empty
.lnr-cart-full
  &:before
    content lnr-cart-full
.lnr-cart-plus
  &:before
    content lnr-cart-plus
.lnr-cart-plus2
  &:before
    content lnr-cart-plus2
.lnr-cart-add
  &:before
    content lnr-cart-add
.lnr-cart-remove
  &:before
    content lnr-cart-remove
.lnr-cart-exchange
  &:before
    content lnr-cart-exchange
.lnr-tag
  &:before
    content lnr-tag
.lnr-tags
  &:before
    content lnr-tags
.lnr-receipt
  &:before
    content lnr-receipt
.lnr-wallet
  &:before
    content lnr-wallet
.lnr-credit-card
  &:before
    content lnr-credit-card
.lnr-cash-dollar
  &:before
    content lnr-cash-dollar
.lnr-cash-euro
  &:before
    content lnr-cash-euro
.lnr-cash-pound
  &:before
    content lnr-cash-pound
.lnr-cash-yen
  &:before
    content lnr-cash-yen
.lnr-bag-dollar
  &:before
    content lnr-bag-dollar
.lnr-bag-euro
  &:before
    content lnr-bag-euro
.lnr-bag-pound
  &:before
    content lnr-bag-pound
.lnr-bag-yen
  &:before
    content lnr-bag-yen
.lnr-coin-dollar
  &:before
    content lnr-coin-dollar
.lnr-coin-euro
  &:before
    content lnr-coin-euro
.lnr-coin-pound
  &:before
    content lnr-coin-pound
.lnr-coin-yen
  &:before
    content lnr-coin-yen
.lnr-calculator
  &:before
    content lnr-calculator
.lnr-calculator2
  &:before
    content lnr-calculator2
.lnr-abacus
  &:before
    content lnr-abacus
.lnr-vault
  &:before
    content lnr-vault
.lnr-telephone
  &:before
    content lnr-telephone
.lnr-phone-lock
  &:before
    content lnr-phone-lock
.lnr-phone-wave
  &:before
    content lnr-phone-wave
.lnr-phone-pause
  &:before
    content lnr-phone-pause
.lnr-phone-outgoing
  &:before
    content lnr-phone-outgoing
.lnr-phone-incoming
  &:before
    content lnr-phone-incoming
.lnr-phone-in-out
  &:before
    content lnr-phone-in-out
.lnr-phone-error
  &:before
    content lnr-phone-error
.lnr-phone-sip
  &:before
    content lnr-phone-sip
.lnr-phone-plus
  &:before
    content lnr-phone-plus
.lnr-phone-minus
  &:before
    content lnr-phone-minus
.lnr-voicemail
  &:before
    content lnr-voicemail
.lnr-dial
  &:before
    content lnr-dial
.lnr-telephone2
  &:before
    content lnr-telephone2
.lnr-pushpin
  &:before
    content lnr-pushpin
.lnr-pushpin2
  &:before
    content lnr-pushpin2
.lnr-map-marker
  &:before
    content lnr-map-marker
.lnr-map-marker-user
  &:before
    content lnr-map-marker-user
.lnr-map-marker-down
  &:before
    content lnr-map-marker-down
.lnr-map-marker-check
  &:before
    content lnr-map-marker-check
.lnr-map-marker-crossed
  &:before
    content lnr-map-marker-crossed
.lnr-radar
  &:before
    content lnr-radar
.lnr-compass2
  &:before
    content lnr-compass2
.lnr-map
  &:before
    content lnr-map
.lnr-map2
  &:before
    content lnr-map2
.lnr-location
  &:before
    content lnr-location
.lnr-road-sign
  &:before
    content lnr-road-sign
.lnr-calendar-empty
  &:before
    content lnr-calendar-empty
.lnr-calendar-check
  &:before
    content lnr-calendar-check
.lnr-calendar-cross
  &:before
    content lnr-calendar-cross
.lnr-calendar-31
  &:before
    content lnr-calendar-31
.lnr-calendar-full
  &:before
    content lnr-calendar-full
.lnr-calendar-insert
  &:before
    content lnr-calendar-insert
.lnr-calendar-text
  &:before
    content lnr-calendar-text
.lnr-calendar-user
  &:before
    content lnr-calendar-user
.lnr-mouse
  &:before
    content lnr-mouse
.lnr-mouse-left
  &:before
    content lnr-mouse-left
.lnr-mouse-right
  &:before
    content lnr-mouse-right
.lnr-mouse-both
  &:before
    content lnr-mouse-both
.lnr-keyboard
  &:before
    content lnr-keyboard
.lnr-keyboard-up
  &:before
    content lnr-keyboard-up
.lnr-keyboard-down
  &:before
    content lnr-keyboard-down
.lnr-delete
  &:before
    content lnr-delete
.lnr-spell-check
  &:before
    content lnr-spell-check
.lnr-escape
  &:before
    content lnr-escape
.lnr-enter2
  &:before
    content lnr-enter2
.lnr-screen
  &:before
    content lnr-screen
.lnr-aspect-ratio
  &:before
    content lnr-aspect-ratio
.lnr-signal
  &:before
    content lnr-signal
.lnr-signal-lock
  &:before
    content lnr-signal-lock
.lnr-signal-80
  &:before
    content lnr-signal-80
.lnr-signal-60
  &:before
    content lnr-signal-60
.lnr-signal-40
  &:before
    content lnr-signal-40
.lnr-signal-20
  &:before
    content lnr-signal-20
.lnr-signal-0
  &:before
    content lnr-signal-0
.lnr-signal-blocked
  &:before
    content lnr-signal-blocked
.lnr-sim
  &:before
    content lnr-sim
.lnr-flash-memory
  &:before
    content lnr-flash-memory
.lnr-usb-drive
  &:before
    content lnr-usb-drive
.lnr-phone
  &:before
    content lnr-phone
.lnr-smartphone
  &:before
    content lnr-smartphone
.lnr-smartphone-notification
  &:before
    content lnr-smartphone-notification
.lnr-smartphone-vibration
  &:before
    content lnr-smartphone-vibration
.lnr-smartphone-embed
  &:before
    content lnr-smartphone-embed
.lnr-smartphone-waves
  &:before
    content lnr-smartphone-waves
.lnr-tablet
  &:before
    content lnr-tablet
.lnr-tablet2
  &:before
    content lnr-tablet2
.lnr-laptop
  &:before
    content lnr-laptop
.lnr-laptop-phone
  &:before
    content lnr-laptop-phone
.lnr-desktop
  &:before
    content lnr-desktop
.lnr-launch
  &:before
    content lnr-launch
.lnr-new-tab
  &:before
    content lnr-new-tab
.lnr-window
  &:before
    content lnr-window
.lnr-cable
  &:before
    content lnr-cable
.lnr-cable2
  &:before
    content lnr-cable2
.lnr-tv
  &:before
    content lnr-tv
.lnr-radio
  &:before
    content lnr-radio
.lnr-remote-control
  &:before
    content lnr-remote-control
.lnr-power-switch
  &:before
    content lnr-power-switch
.lnr-power
  &:before
    content lnr-power
.lnr-power-crossed
  &:before
    content lnr-power-crossed
.lnr-flash-auto
  &:before
    content lnr-flash-auto
.lnr-lamp
  &:before
    content lnr-lamp
.lnr-flashlight
  &:before
    content lnr-flashlight
.lnr-lampshade
  &:before
    content lnr-lampshade
.lnr-cord
  &:before
    content lnr-cord
.lnr-outlet
  &:before
    content lnr-outlet
.lnr-battery-power
  &:before
    content lnr-battery-power
.lnr-battery-empty
  &:before
    content lnr-battery-empty
.lnr-battery-alert
  &:before
    content lnr-battery-alert
.lnr-battery-error
  &:before
    content lnr-battery-error
.lnr-battery-low1
  &:before
    content lnr-battery-low1
.lnr-battery-low2
  &:before
    content lnr-battery-low2
.lnr-battery-low3
  &:before
    content lnr-battery-low3
.lnr-battery-mid1
  &:before
    content lnr-battery-mid1
.lnr-battery-mid2
  &:before
    content lnr-battery-mid2
.lnr-battery-mid3
  &:before
    content lnr-battery-mid3
.lnr-battery-full
  &:before
    content lnr-battery-full
.lnr-battery-charging
  &:before
    content lnr-battery-charging
.lnr-battery-charging2
  &:before
    content lnr-battery-charging2
.lnr-battery-charging3
  &:before
    content lnr-battery-charging3
.lnr-battery-charging4
  &:before
    content lnr-battery-charging4
.lnr-battery-charging5
  &:before
    content lnr-battery-charging5
.lnr-battery-charging6
  &:before
    content lnr-battery-charging6
.lnr-battery-charging7
  &:before
    content lnr-battery-charging7
.lnr-chip
  &:before
    content lnr-chip
.lnr-chip-x64
  &:before
    content lnr-chip-x64
.lnr-chip-x86
  &:before
    content lnr-chip-x86
.lnr-bubble
  &:before
    content lnr-bubble
.lnr-bubbles
  &:before
    content lnr-bubbles
.lnr-bubble-dots
  &:before
    content lnr-bubble-dots
.lnr-bubble-alert
  &:before
    content lnr-bubble-alert
.lnr-bubble-question
  &:before
    content lnr-bubble-question
.lnr-bubble-text
  &:before
    content lnr-bubble-text
.lnr-bubble-pencil
  &:before
    content lnr-bubble-pencil
.lnr-bubble-picture
  &:before
    content lnr-bubble-picture
.lnr-bubble-video
  &:before
    content lnr-bubble-video
.lnr-bubble-user
  &:before
    content lnr-bubble-user
.lnr-bubble-quote
  &:before
    content lnr-bubble-quote
.lnr-bubble-heart
  &:before
    content lnr-bubble-heart
.lnr-bubble-emoticon
  &:before
    content lnr-bubble-emoticon
.lnr-bubble-attachment
  &:before
    content lnr-bubble-attachment
.lnr-phone-bubble
  &:before
    content lnr-phone-bubble
.lnr-quote-open
  &:before
    content lnr-quote-open
.lnr-quote-close
  &:before
    content lnr-quote-close
.lnr-dna
  &:before
    content lnr-dna
.lnr-heart-pulse
  &:before
    content lnr-heart-pulse
.lnr-pulse
  &:before
    content lnr-pulse
.lnr-syringe
  &:before
    content lnr-syringe
.lnr-pills
  &:before
    content lnr-pills
.lnr-first-aid
  &:before
    content lnr-first-aid
.lnr-lifebuoy
  &:before
    content lnr-lifebuoy
.lnr-bandage
  &:before
    content lnr-bandage
.lnr-bandages
  &:before
    content lnr-bandages
.lnr-thermometer
  &:before
    content lnr-thermometer
.lnr-microscope
  &:before
    content lnr-microscope
.lnr-brain
  &:before
    content lnr-brain
.lnr-beaker
  &:before
    content lnr-beaker
.lnr-skull
  &:before
    content lnr-skull
.lnr-bone
  &:before
    content lnr-bone
.lnr-construction
  &:before
    content lnr-construction
.lnr-construction-cone
  &:before
    content lnr-construction-cone
.lnr-pie-chart
  &:before
    content lnr-pie-chart
.lnr-pie-chart2
  &:before
    content lnr-pie-chart2
.lnr-graph
  &:before
    content lnr-graph
.lnr-chart-growth
  &:before
    content lnr-chart-growth
.lnr-chart-bars
  &:before
    content lnr-chart-bars
.lnr-chart-settings
  &:before
    content lnr-chart-settings
.lnr-cake
  &:before
    content lnr-cake
.lnr-gift
  &:before
    content lnr-gift
.lnr-balloon
  &:before
    content lnr-balloon
.lnr-rank
  &:before
    content lnr-rank
.lnr-rank2
  &:before
    content lnr-rank2
.lnr-rank3
  &:before
    content lnr-rank3
.lnr-crown
  &:before
    content lnr-crown
.lnr-lotus
  &:before
    content lnr-lotus
.lnr-diamond
  &:before
    content lnr-diamond
.lnr-diamond2
  &:before
    content lnr-diamond2
.lnr-diamond3
  &:before
    content lnr-diamond3
.lnr-diamond4
  &:before
    content lnr-diamond4
.lnr-linearicons
  &:before
    content lnr-linearicons
.lnr-teacup
  &:before
    content lnr-teacup
.lnr-teapot
  &:before
    content lnr-teapot
.lnr-glass
  &:before
    content lnr-glass
.lnr-bottle2
  &:before
    content lnr-bottle2
.lnr-glass-cocktail
  &:before
    content lnr-glass-cocktail
.lnr-glass2
  &:before
    content lnr-glass2
.lnr-dinner
  &:before
    content lnr-dinner
.lnr-dinner2
  &:before
    content lnr-dinner2
.lnr-chef
  &:before
    content lnr-chef
.lnr-scale2
  &:before
    content lnr-scale2
.lnr-egg
  &:before
    content lnr-egg
.lnr-egg2
  &:before
    content lnr-egg2
.lnr-eggs
  &:before
    content lnr-eggs
.lnr-platter
  &:before
    content lnr-platter
.lnr-steak
  &:before
    content lnr-steak
.lnr-hamburger
  &:before
    content lnr-hamburger
.lnr-hotdog
  &:before
    content lnr-hotdog
.lnr-pizza
  &:before
    content lnr-pizza
.lnr-sausage
  &:before
    content lnr-sausage
.lnr-chicken
  &:before
    content lnr-chicken
.lnr-fish
  &:before
    content lnr-fish
.lnr-carrot
  &:before
    content lnr-carrot
.lnr-cheese
  &:before
    content lnr-cheese
.lnr-bread
  &:before
    content lnr-bread
.lnr-ice-cream
  &:before
    content lnr-ice-cream
.lnr-ice-cream2
  &:before
    content lnr-ice-cream2
.lnr-candy
  &:before
    content lnr-candy
.lnr-lollipop
  &:before
    content lnr-lollipop
.lnr-coffee-bean
  &:before
    content lnr-coffee-bean
.lnr-coffee-cup
  &:before
    content lnr-coffee-cup
.lnr-cherry
  &:before
    content lnr-cherry
.lnr-grapes
  &:before
    content lnr-grapes
.lnr-citrus
  &:before
    content lnr-citrus
.lnr-apple
  &:before
    content lnr-apple
.lnr-leaf
  &:before
    content lnr-leaf
.lnr-landscape
  &:before
    content lnr-landscape
.lnr-pine-tree
  &:before
    content lnr-pine-tree
.lnr-tree
  &:before
    content lnr-tree
.lnr-cactus
  &:before
    content lnr-cactus
.lnr-paw
  &:before
    content lnr-paw
.lnr-footprint
  &:before
    content lnr-footprint
.lnr-speed-slow
  &:before
    content lnr-speed-slow
.lnr-speed-medium
  &:before
    content lnr-speed-medium
.lnr-speed-fast
  &:before
    content lnr-speed-fast
.lnr-rocket
  &:before
    content lnr-rocket
.lnr-hammer2
  &:before
    content lnr-hammer2
.lnr-balance
  &:before
    content lnr-balance
.lnr-briefcase
  &:before
    content lnr-briefcase
.lnr-luggage-weight
  &:before
    content lnr-luggage-weight
.lnr-dolly
  &:before
    content lnr-dolly
.lnr-plane
  &:before
    content lnr-plane
.lnr-plane-crossed
  &:before
    content lnr-plane-crossed
.lnr-helicopter
  &:before
    content lnr-helicopter
.lnr-traffic-lights
  &:before
    content lnr-traffic-lights
.lnr-siren
  &:before
    content lnr-siren
.lnr-road
  &:before
    content lnr-road
.lnr-engine
  &:before
    content lnr-engine
.lnr-oil-pressure
  &:before
    content lnr-oil-pressure
.lnr-coolant-temperature
  &:before
    content lnr-coolant-temperature
.lnr-car-battery
  &:before
    content lnr-car-battery
.lnr-gas
  &:before
    content lnr-gas
.lnr-gallon
  &:before
    content lnr-gallon
.lnr-transmission
  &:before
    content lnr-transmission
.lnr-car
  &:before
    content lnr-car
.lnr-car-wash
  &:before
    content lnr-car-wash
.lnr-car-wash2
  &:before
    content lnr-car-wash2
.lnr-bus
  &:before
    content lnr-bus
.lnr-bus2
  &:before
    content lnr-bus2
.lnr-car2
  &:before
    content lnr-car2
.lnr-parking
  &:before
    content lnr-parking
.lnr-car-lock
  &:before
    content lnr-car-lock
.lnr-taxi
  &:before
    content lnr-taxi
.lnr-car-siren
  &:before
    content lnr-car-siren
.lnr-car-wash3
  &:before
    content lnr-car-wash3
.lnr-car-wash4
  &:before
    content lnr-car-wash4
.lnr-ambulance
  &:before
    content lnr-ambulance
.lnr-truck
  &:before
    content lnr-truck
.lnr-trailer
  &:before
    content lnr-trailer
.lnr-scale-truck
  &:before
    content lnr-scale-truck
.lnr-train
  &:before
    content lnr-train
.lnr-ship
  &:before
    content lnr-ship
.lnr-ship2
  &:before
    content lnr-ship2
.lnr-anchor
  &:before
    content lnr-anchor
.lnr-boat
  &:before
    content lnr-boat
.lnr-bicycle
  &:before
    content lnr-bicycle
.lnr-bicycle2
  &:before
    content lnr-bicycle2
.lnr-dumbbell
  &:before
    content lnr-dumbbell
.lnr-bench-press
  &:before
    content lnr-bench-press
.lnr-swim
  &:before
    content lnr-swim
.lnr-football
  &:before
    content lnr-football
.lnr-baseball-bat
  &:before
    content lnr-baseball-bat
.lnr-baseball
  &:before
    content lnr-baseball
.lnr-tennis
  &:before
    content lnr-tennis
.lnr-tennis2
  &:before
    content lnr-tennis2
.lnr-ping-pong
  &:before
    content lnr-ping-pong
.lnr-hockey
  &:before
    content lnr-hockey
.lnr-8ball
  &:before
    content lnr-8ball
.lnr-bowling
  &:before
    content lnr-bowling
.lnr-bowling-pins
  &:before
    content lnr-bowling-pins
.lnr-golf
  &:before
    content lnr-golf
.lnr-golf2
  &:before
    content lnr-golf2
.lnr-archery
  &:before
    content lnr-archery
.lnr-slingshot
  &:before
    content lnr-slingshot
.lnr-soccer
  &:before
    content lnr-soccer
.lnr-basketball
  &:before
    content lnr-basketball
.lnr-cube
  &:before
    content lnr-cube
.lnr-3d-rotate
  &:before
    content lnr-3d-rotate
.lnr-puzzle
  &:before
    content lnr-puzzle
.lnr-glasses
  &:before
    content lnr-glasses
.lnr-glasses2
  &:before
    content lnr-glasses2
.lnr-accessibility
  &:before
    content lnr-accessibility
.lnr-wheelchair
  &:before
    content lnr-wheelchair
.lnr-wall
  &:before
    content lnr-wall
.lnr-fence
  &:before
    content lnr-fence
.lnr-wall2
  &:before
    content lnr-wall2
.lnr-icons
  &:before
    content lnr-icons
.lnr-resize-handle
  &:before
    content lnr-resize-handle
.lnr-icons2
  &:before
    content lnr-icons2
.lnr-select
  &:before
    content lnr-select
.lnr-select2
  &:before
    content lnr-select2
.lnr-site-map
  &:before
    content lnr-site-map
.lnr-earth
  &:before
    content lnr-earth
.lnr-earth-lock
  &:before
    content lnr-earth-lock
.lnr-network
  &:before
    content lnr-network
.lnr-network-lock
  &:before
    content lnr-network-lock
.lnr-planet
  &:before
    content lnr-planet
.lnr-happy
  &:before
    content lnr-happy
.lnr-smile
  &:before
    content lnr-smile
.lnr-grin
  &:before
    content lnr-grin
.lnr-tongue
  &:before
    content lnr-tongue
.lnr-sad
  &:before
    content lnr-sad
.lnr-wink
  &:before
    content lnr-wink
.lnr-dream
  &:before
    content lnr-dream
.lnr-shocked
  &:before
    content lnr-shocked
.lnr-shocked2
  &:before
    content lnr-shocked2
.lnr-tongue2
  &:before
    content lnr-tongue2
.lnr-neutral
  &:before
    content lnr-neutral
.lnr-happy-grin
  &:before
    content lnr-happy-grin
.lnr-cool
  &:before
    content lnr-cool
.lnr-mad
  &:before
    content lnr-mad
.lnr-grin-evil
  &:before
    content lnr-grin-evil
.lnr-evil
  &:before
    content lnr-evil
.lnr-wow
  &:before
    content lnr-wow
.lnr-annoyed
  &:before
    content lnr-annoyed
.lnr-wondering
  &:before
    content lnr-wondering
.lnr-confused
  &:before
    content lnr-confused
.lnr-zipped
  &:before
    content lnr-zipped
.lnr-grumpy
  &:before
    content lnr-grumpy
.lnr-mustache
  &:before
    content lnr-mustache
.lnr-tombstone-hipster
  &:before
    content lnr-tombstone-hipster
.lnr-tombstone
  &:before
    content lnr-tombstone
.lnr-ghost
  &:before
    content lnr-ghost
.lnr-ghost-hipster
  &:before
    content lnr-ghost-hipster
.lnr-halloween
  &:before
    content lnr-halloween
.lnr-christmas
  &:before
    content lnr-christmas
.lnr-easter-egg
  &:before
    content lnr-easter-egg
.lnr-mustache2
  &:before
    content lnr-mustache2
.lnr-mustache-glasses
  &:before
    content lnr-mustache-glasses
.lnr-pipe
  &:before
    content lnr-pipe
.lnr-alarm
  &:before
    content lnr-alarm
.lnr-alarm-add
  &:before
    content lnr-alarm-add
.lnr-alarm-snooze
  &:before
    content lnr-alarm-snooze
.lnr-alarm-ringing
  &:before
    content lnr-alarm-ringing
.lnr-bullhorn
  &:before
    content lnr-bullhorn
.lnr-hearing
  &:before
    content lnr-hearing
.lnr-volume-high
  &:before
    content lnr-volume-high
.lnr-volume-medium
  &:before
    content lnr-volume-medium
.lnr-volume-low
  &:before
    content lnr-volume-low
.lnr-volume
  &:before
    content lnr-volume
.lnr-mute
  &:before
    content lnr-mute
.lnr-lan
  &:before
    content lnr-lan
.lnr-lan2
  &:before
    content lnr-lan2
.lnr-wifi
  &:before
    content lnr-wifi
.lnr-wifi-lock
  &:before
    content lnr-wifi-lock
.lnr-wifi-blocked
  &:before
    content lnr-wifi-blocked
.lnr-wifi-mid
  &:before
    content lnr-wifi-mid
.lnr-wifi-low
  &:before
    content lnr-wifi-low
.lnr-wifi-low2
  &:before
    content lnr-wifi-low2
.lnr-wifi-alert
  &:before
    content lnr-wifi-alert
.lnr-wifi-alert-mid
  &:before
    content lnr-wifi-alert-mid
.lnr-wifi-alert-low
  &:before
    content lnr-wifi-alert-low
.lnr-wifi-alert-low2
  &:before
    content lnr-wifi-alert-low2
.lnr-stream
  &:before
    content lnr-stream
.lnr-stream-check
  &:before
    content lnr-stream-check
.lnr-stream-error
  &:before
    content lnr-stream-error
.lnr-stream-alert
  &:before
    content lnr-stream-alert
.lnr-communication
  &:before
    content lnr-communication
.lnr-communication-crossed
  &:before
    content lnr-communication-crossed
.lnr-broadcast
  &:before
    content lnr-broadcast
.lnr-antenna
  &:before
    content lnr-antenna
.lnr-satellite
  &:before
    content lnr-satellite
.lnr-satellite2
  &:before
    content lnr-satellite2
.lnr-mic
  &:before
    content lnr-mic
.lnr-mic-mute
  &:before
    content lnr-mic-mute
.lnr-mic2
  &:before
    content lnr-mic2
.lnr-spotlights
  &:before
    content lnr-spotlights
.lnr-hourglass
  &:before
    content lnr-hourglass
.lnr-loading
  &:before
    content lnr-loading
.lnr-loading2
  &:before
    content lnr-loading2
.lnr-loading3
  &:before
    content lnr-loading3
.lnr-refresh
  &:before
    content lnr-refresh
.lnr-refresh2
  &:before
    content lnr-refresh2
.lnr-undo
  &:before
    content lnr-undo
.lnr-redo
  &:before
    content lnr-redo
.lnr-jump2
  &:before
    content lnr-jump2
.lnr-undo2
  &:before
    content lnr-undo2
.lnr-redo2
  &:before
    content lnr-redo2
.lnr-sync
  &:before
    content lnr-sync
.lnr-repeat-one2
  &:before
    content lnr-repeat-one2
.lnr-sync-crossed
  &:before
    content lnr-sync-crossed
.lnr-sync2
  &:before
    content lnr-sync2
.lnr-repeat-one3
  &:before
    content lnr-repeat-one3
.lnr-sync-crossed2
  &:before
    content lnr-sync-crossed2
.lnr-return
  &:before
    content lnr-return
.lnr-return2
  &:before
    content lnr-return2
.lnr-refund
  &:before
    content lnr-refund
.lnr-history
  &:before
    content lnr-history
.lnr-history2
  &:before
    content lnr-history2
.lnr-self-timer
  &:before
    content lnr-self-timer
.lnr-clock
  &:before
    content lnr-clock
.lnr-clock2
  &:before
    content lnr-clock2
.lnr-clock3
  &:before
    content lnr-clock3
.lnr-watch
  &:before
    content lnr-watch
.lnr-alarm2
  &:before
    content lnr-alarm2
.lnr-alarm-add2
  &:before
    content lnr-alarm-add2
.lnr-alarm-remove
  &:before
    content lnr-alarm-remove
.lnr-alarm-check
  &:before
    content lnr-alarm-check
.lnr-alarm-error
  &:before
    content lnr-alarm-error
.lnr-timer
  &:before
    content lnr-timer
.lnr-timer-crossed
  &:before
    content lnr-timer-crossed
.lnr-timer2
  &:before
    content lnr-timer2
.lnr-timer-crossed2
  &:before
    content lnr-timer-crossed2
.lnr-download
  &:before
    content lnr-download
.lnr-upload
  &:before
    content lnr-upload
.lnr-download2
  &:before
    content lnr-download2
.lnr-upload2
  &:before
    content lnr-upload2
.lnr-enter-up
  &:before
    content lnr-enter-up
.lnr-enter-down
  &:before
    content lnr-enter-down
.lnr-enter-left
  &:before
    content lnr-enter-left
.lnr-enter-right
  &:before
    content lnr-enter-right
.lnr-exit-up
  &:before
    content lnr-exit-up
.lnr-exit-down
  &:before
    content lnr-exit-down
.lnr-exit-left
  &:before
    content lnr-exit-left
.lnr-exit-right
  &:before
    content lnr-exit-right
.lnr-enter-up2
  &:before
    content lnr-enter-up2
.lnr-enter-down2
  &:before
    content lnr-enter-down2
.lnr-enter-vertical
  &:before
    content lnr-enter-vertical
.lnr-enter-left2
  &:before
    content lnr-enter-left2
.lnr-enter-right2
  &:before
    content lnr-enter-right2
.lnr-enter-horizontal
  &:before
    content lnr-enter-horizontal
.lnr-exit-up2
  &:before
    content lnr-exit-up2
.lnr-exit-down2
  &:before
    content lnr-exit-down2
.lnr-exit-left2
  &:before
    content lnr-exit-left2
.lnr-exit-right2
  &:before
    content lnr-exit-right2
.lnr-cli
  &:before
    content lnr-cli
.lnr-bug
  &:before
    content lnr-bug
.lnr-code
  &:before
    content lnr-code
.lnr-file-code
  &:before
    content lnr-file-code
.lnr-file-image
  &:before
    content lnr-file-image
.lnr-file-zip
  &:before
    content lnr-file-zip
.lnr-file-audio
  &:before
    content lnr-file-audio
.lnr-file-video
  &:before
    content lnr-file-video
.lnr-file-preview
  &:before
    content lnr-file-preview
.lnr-file-charts
  &:before
    content lnr-file-charts
.lnr-file-stats
  &:before
    content lnr-file-stats
.lnr-file-spreadsheet
  &:before
    content lnr-file-spreadsheet
.lnr-link
  &:before
    content lnr-link
.lnr-unlink
  &:before
    content lnr-unlink
.lnr-link2
  &:before
    content lnr-link2
.lnr-unlink2
  &:before
    content lnr-unlink2
.lnr-thumbs-up
  &:before
    content lnr-thumbs-up
.lnr-thumbs-down
  &:before
    content lnr-thumbs-down
.lnr-thumbs-up2
  &:before
    content lnr-thumbs-up2
.lnr-thumbs-down2
  &:before
    content lnr-thumbs-down2
.lnr-thumbs-up3
  &:before
    content lnr-thumbs-up3
.lnr-thumbs-down3
  &:before
    content lnr-thumbs-down3
.lnr-share
  &:before
    content lnr-share
.lnr-share2
  &:before
    content lnr-share2
.lnr-share3
  &:before
    content lnr-share3
.lnr-magnifier
  &:before
    content lnr-magnifier
.lnr-file-search
  &:before
    content lnr-file-search
.lnr-find-replace
  &:before
    content lnr-find-replace
.lnr-zoom-in
  &:before
    content lnr-zoom-in
.lnr-zoom-out
  &:before
    content lnr-zoom-out
.lnr-loupe
  &:before
    content lnr-loupe
.lnr-loupe-zoom-in
  &:before
    content lnr-loupe-zoom-in
.lnr-loupe-zoom-out
  &:before
    content lnr-loupe-zoom-out
.lnr-cross
  &:before
    content lnr-cross
.lnr-menu
  &:before
    content lnr-menu
.lnr-list
  &:before
    content lnr-list
.lnr-list2
  &:before
    content lnr-list2
.lnr-list3
  &:before
    content lnr-list3
.lnr-menu2
  &:before
    content lnr-menu2
.lnr-list4
  &:before
    content lnr-list4
.lnr-menu3
  &:before
    content lnr-menu3
.lnr-exclamation
  &:before
    content lnr-exclamation
.lnr-question
  &:before
    content lnr-question
.lnr-check
  &:before
    content lnr-check
.lnr-cross2
  &:before
    content lnr-cross2
.lnr-plus
  &:before
    content lnr-plus
.lnr-minus
  &:before
    content lnr-minus
.lnr-percent
  &:before
    content lnr-percent
.lnr-chevron-up
  &:before
    content lnr-chevron-up
.lnr-chevron-down
  &:before
    content lnr-chevron-down
.lnr-chevron-left
  &:before
    content lnr-chevron-left
.lnr-chevron-right
  &:before
    content lnr-chevron-right
.lnr-chevrons-expand-vertical
  &:before
    content lnr-chevrons-expand-vertical
.lnr-chevrons-expand-horizontal
  &:before
    content lnr-chevrons-expand-horizontal
.lnr-chevrons-contract-vertical
  &:before
    content lnr-chevrons-contract-vertical
.lnr-chevrons-contract-horizontal
  &:before
    content lnr-chevrons-contract-horizontal
.lnr-arrow-up
  &:before
    content lnr-arrow-up
.lnr-arrow-down
  &:before
    content lnr-arrow-down
.lnr-arrow-left
  &:before
    content lnr-arrow-left
.lnr-arrow-right
  &:before
    content lnr-arrow-right
.lnr-arrow-up-right
  &:before
    content lnr-arrow-up-right
.lnr-arrows-merge
  &:before
    content lnr-arrows-merge
.lnr-arrows-split
  &:before
    content lnr-arrows-split
.lnr-arrow-divert
  &:before
    content lnr-arrow-divert
.lnr-arrow-return
  &:before
    content lnr-arrow-return
.lnr-expand
  &:before
    content lnr-expand
.lnr-contract
  &:before
    content lnr-contract
.lnr-expand2
  &:before
    content lnr-expand2
.lnr-contract2
  &:before
    content lnr-contract2
.lnr-move
  &:before
    content lnr-move
.lnr-tab
  &:before
    content lnr-tab
.lnr-arrow-wave
  &:before
    content lnr-arrow-wave
.lnr-expand3
  &:before
    content lnr-expand3
.lnr-expand4
  &:before
    content lnr-expand4
.lnr-contract3
  &:before
    content lnr-contract3
.lnr-notification
  &:before
    content lnr-notification
.lnr-warning
  &:before
    content lnr-warning
.lnr-notification-circle
  &:before
    content lnr-notification-circle
.lnr-question-circle
  &:before
    content lnr-question-circle
.lnr-menu-circle
  &:before
    content lnr-menu-circle
.lnr-checkmark-circle
  &:before
    content lnr-checkmark-circle
.lnr-cross-circle
  &:before
    content lnr-cross-circle
.lnr-plus-circle
  &:before
    content lnr-plus-circle
.lnr-circle-minus
  &:before
    content lnr-circle-minus
.lnr-percent-circle
  &:before
    content lnr-percent-circle
.lnr-arrow-up-circle
  &:before
    content lnr-arrow-up-circle
.lnr-arrow-down-circle
  &:before
    content lnr-arrow-down-circle
.lnr-arrow-left-circle
  &:before
    content lnr-arrow-left-circle
.lnr-arrow-right-circle
  &:before
    content lnr-arrow-right-circle
.lnr-chevron-up-circle
  &:before
    content lnr-chevron-up-circle
.lnr-chevron-down-circle
  &:before
    content lnr-chevron-down-circle
.lnr-chevron-left-circle
  &:before
    content lnr-chevron-left-circle
.lnr-chevron-right-circle
  &:before
    content lnr-chevron-right-circle
.lnr-backward-circle
  &:before
    content lnr-backward-circle
.lnr-first-circle
  &:before
    content lnr-first-circle
.lnr-previous-circle
  &:before
    content lnr-previous-circle
.lnr-stop-circle
  &:before
    content lnr-stop-circle
.lnr-play-circle
  &:before
    content lnr-play-circle
.lnr-pause-circle
  &:before
    content lnr-pause-circle
.lnr-next-circle
  &:before
    content lnr-next-circle
.lnr-last-circle
  &:before
    content lnr-last-circle
.lnr-forward-circle
  &:before
    content lnr-forward-circle
.lnr-eject-circle
  &:before
    content lnr-eject-circle
.lnr-crop
  &:before
    content lnr-crop
.lnr-frame-expand
  &:before
    content lnr-frame-expand
.lnr-frame-contract
  &:before
    content lnr-frame-contract
.lnr-focus
  &:before
    content lnr-focus
.lnr-transform
  &:before
    content lnr-transform
.lnr-grid
  &:before
    content lnr-grid
.lnr-grid-crossed
  &:before
    content lnr-grid-crossed
.lnr-layers
  &:before
    content lnr-layers
.lnr-layers-crossed
  &:before
    content lnr-layers-crossed
.lnr-toggle
  &:before
    content lnr-toggle
.lnr-rulers
  &:before
    content lnr-rulers
.lnr-ruler
  &:before
    content lnr-ruler
.lnr-funnel
  &:before
    content lnr-funnel
.lnr-flip-horizontal
  &:before
    content lnr-flip-horizontal
.lnr-flip-vertical
  &:before
    content lnr-flip-vertical
.lnr-flip-horizontal2
  &:before
    content lnr-flip-horizontal2
.lnr-flip-vertical2
  &:before
    content lnr-flip-vertical2
.lnr-angle
  &:before
    content lnr-angle
.lnr-angle2
  &:before
    content lnr-angle2
.lnr-subtract
  &:before
    content lnr-subtract
.lnr-combine
  &:before
    content lnr-combine
.lnr-intersect
  &:before
    content lnr-intersect
.lnr-exclude
  &:before
    content lnr-exclude
.lnr-align-center-vertical
  &:before
    content lnr-align-center-vertical
.lnr-align-right
  &:before
    content lnr-align-right
.lnr-align-bottom
  &:before
    content lnr-align-bottom
.lnr-align-left
  &:before
    content lnr-align-left
.lnr-align-center-horizontal
  &:before
    content lnr-align-center-horizontal
.lnr-align-top
  &:before
    content lnr-align-top
.lnr-square
  &:before
    content lnr-square
.lnr-plus-square
  &:before
    content lnr-plus-square
.lnr-minus-square
  &:before
    content lnr-minus-square
.lnr-percent-square
  &:before
    content lnr-percent-square
.lnr-arrow-up-square
  &:before
    content lnr-arrow-up-square
.lnr-arrow-down-square
  &:before
    content lnr-arrow-down-square
.lnr-arrow-left-square
  &:before
    content lnr-arrow-left-square
.lnr-arrow-right-square
  &:before
    content lnr-arrow-right-square
.lnr-chevron-up-square
  &:before
    content lnr-chevron-up-square
.lnr-chevron-down-square
  &:before
    content lnr-chevron-down-square
.lnr-chevron-left-square
  &:before
    content lnr-chevron-left-square
.lnr-chevron-right-square
  &:before
    content lnr-chevron-right-square
.lnr-check-square
  &:before
    content lnr-check-square
.lnr-cross-square
  &:before
    content lnr-cross-square
.lnr-menu-square
  &:before
    content lnr-menu-square
.lnr-prohibited
  &:before
    content lnr-prohibited
.lnr-circle
  &:before
    content lnr-circle
.lnr-radio-button
  &:before
    content lnr-radio-button
.lnr-ligature
  &:before
    content lnr-ligature
.lnr-text-format
  &:before
    content lnr-text-format
.lnr-text-format-remove
  &:before
    content lnr-text-format-remove
.lnr-text-size
  &:before
    content lnr-text-size
.lnr-bold
  &:before
    content lnr-bold
.lnr-italic
  &:before
    content lnr-italic
.lnr-underline
  &:before
    content lnr-underline
.lnr-strikethrough
  &:before
    content lnr-strikethrough
.lnr-highlight
  &:before
    content lnr-highlight
.lnr-text-align-left
  &:before
    content lnr-text-align-left
.lnr-text-align-center
  &:before
    content lnr-text-align-center
.lnr-text-align-right
  &:before
    content lnr-text-align-right
.lnr-text-align-justify
  &:before
    content lnr-text-align-justify
.lnr-line-spacing
  &:before
    content lnr-line-spacing
.lnr-indent-increase
  &:before
    content lnr-indent-increase
.lnr-indent-decrease
  &:before
    content lnr-indent-decrease
.lnr-text-wrap
  &:before
    content lnr-text-wrap
.lnr-pilcrow
  &:before
    content lnr-pilcrow
.lnr-direction-ltr
  &:before
    content lnr-direction-ltr
.lnr-direction-rtl
  &:before
    content lnr-direction-rtl
.lnr-page-break
  &:before
    content lnr-page-break
.lnr-page-break2
  &:before
    content lnr-page-break2
.lnr-sort-alpha-asc
  &:before
    content lnr-sort-alpha-asc
.lnr-sort-alpha-desc
  &:before
    content lnr-sort-alpha-desc
.lnr-sort-numeric-asc
  &:before
    content lnr-sort-numeric-asc
.lnr-sort-numeric-desc
  &:before
    content lnr-sort-numeric-desc
.lnr-sort-amount-asc
  &:before
    content lnr-sort-amount-asc
.lnr-sort-amount-desc
  &:before
    content lnr-sort-amount-desc
.lnr-sort-time-asc
  &:before
    content lnr-sort-time-asc
.lnr-sort-time-desc
  &:before
    content lnr-sort-time-desc
.lnr-sigma
  &:before
    content lnr-sigma
.lnr-pencil-line
  &:before
    content lnr-pencil-line
.lnr-hand
  &:before
    content lnr-hand
.lnr-pointer-up
  &:before
    content lnr-pointer-up
.lnr-pointer-right
  &:before
    content lnr-pointer-right
.lnr-pointer-down
  &:before
    content lnr-pointer-down
.lnr-pointer-left
  &:before
    content lnr-pointer-left
.lnr-finger-tap
  &:before
    content lnr-finger-tap
.lnr-fingers-tap
  &:before
    content lnr-fingers-tap
.lnr-reminder
  &:before
    content lnr-reminder
.lnr-fingers-crossed
  &:before
    content lnr-fingers-crossed
.lnr-fingers-victory
  &:before
    content lnr-fingers-victory
.lnr-gesture-zoom
  &:before
    content lnr-gesture-zoom
.lnr-gesture-pinch
  &:before
    content lnr-gesture-pinch
.lnr-fingers-scroll-horizontal
  &:before
    content lnr-fingers-scroll-horizontal
.lnr-fingers-scroll-vertical
  &:before
    content lnr-fingers-scroll-vertical
.lnr-fingers-scroll-left
  &:before
    content lnr-fingers-scroll-left
.lnr-fingers-scroll-right
  &:before
    content lnr-fingers-scroll-right
.lnr-hand2
  &:before
    content lnr-hand2
.lnr-pointer-up2
  &:before
    content lnr-pointer-up2
.lnr-pointer-right2
  &:before
    content lnr-pointer-right2
.lnr-pointer-down2
  &:before
    content lnr-pointer-down2
.lnr-pointer-left2
  &:before
    content lnr-pointer-left2
.lnr-finger-tap2
  &:before
    content lnr-finger-tap2
.lnr-fingers-tap2
  &:before
    content lnr-fingers-tap2
.lnr-reminder2
  &:before
    content lnr-reminder2
.lnr-gesture-zoom2
  &:before
    content lnr-gesture-zoom2
.lnr-gesture-pinch2
  &:before
    content lnr-gesture-pinch2
.lnr-fingers-scroll-horizontal2
  &:before
    content lnr-fingers-scroll-horizontal2
.lnr-fingers-scroll-vertical2
  &:before
    content lnr-fingers-scroll-vertical2
.lnr-fingers-scroll-left2
  &:before
    content lnr-fingers-scroll-left2
.lnr-fingers-scroll-right2
  &:before
    content lnr-fingers-scroll-right2
.lnr-fingers-scroll-vertical3
  &:before
    content lnr-fingers-scroll-vertical3
.lnr-border-style
  &:before
    content lnr-border-style
.lnr-border-all
  &:before
    content lnr-border-all
.lnr-border-outer
  &:before
    content lnr-border-outer
.lnr-border-inner
  &:before
    content lnr-border-inner
.lnr-border-top
  &:before
    content lnr-border-top
.lnr-border-horizontal
  &:before
    content lnr-border-horizontal
.lnr-border-bottom
  &:before
    content lnr-border-bottom
.lnr-border-left
  &:before
    content lnr-border-left
.lnr-border-vertical
  &:before
    content lnr-border-vertical
.lnr-border-right
  &:before
    content lnr-border-right
.lnr-border-none
  &:before
    content lnr-border-none
.lnr-ellipsis
  &:before
    content lnr-ellipsis
