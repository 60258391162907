$border-mixin(prop)
	&:before
	&:after
		{prop} 100%

	&:before
		border-{prop}-color @border-color

	&:after
		border-{prop}-color @background-color

.menu-widget
	color $primary-font
	position absolute
	border 1px solid
	border-color $background
	border-radius $small-border-radius
	width 12.5rem
	background-color $input-background
	z-index 20
	box-shadow 0 0 3px $card-border
	user-select none

	&:before
	&:after
		border solid transparent
		content ' '
		width 0
		height 0
		position absolute
		pointer-events none

	&:before
		border-width 9px
		margin-left @border-width * -1

	&:after
		border-width 8px
		margin-left @border-width * -1

	&.left
		&:before
		&:after
			left 2rem

	&.right
		&:before
			// not quite sure why this is needed
			right calc(2rem - 1px)

		&:after
			right 2rem

	&.top
		$border-mixin(bottom)

	&.bottom
		$border-mixin(top)

	.content
		$overflow-auto()
		max-height 18rem
		padding 0.65rem 0

		.menu-divider
			border-top 1px solid @border-color
			padding-top 0.75rem !important
			margin-top 0.75rem

	.content > *:not(.menu-ignore):not(.menu-divider)
		padding 0.65rem 0.75rem
		margin 0 0.5rem
		cursor pointer
		font-size 0.9375rem
		border-radius $small-border-radius
		transition all 50ms ease

		&:not(.button-group)
			display block

		&:hover
			background-color $primary
			color $primary-font-pop

		&.alert
			color $red

		&.disabled
			pointer-events none
			color inherit
			cursor initial

			&:hover
				background-color transparent


.actions-menu-widget
	position: relative

	.actions-menu-button
		position: relative
		cursor pointer
		width 2.5rem
		height @width
		border-radius $border-radius
		cursor pointer
		user-select: none
		font-size 1.75rem

		svg
			$center()

	.menu-widget
		right -2rem
		margin-top 0.5rem
		width: 13rem
