$color-auto-gray-7 = #909dab
$color-bg-primary = $foreground
$color-bg-secondary = $foreground
$color-bg-tertiary = $background
$color-border-primary = #444c56
$color-border-secondary = #373e47
$color-border-tertiary = #636e7b
$color-text-danger = #e5534b
$color-text-primary = #adbac7
$color-text-tertiary = #768390
$color-markdown-blockquote-border = hsla(213,12%,35%,1)
$color-markdown-table-border = hsla(213,12%,35%,1)
$color-markdown-table-tr-border = hsla(213,12%,26%,1)
$color-markdown-frame-border = hsla(213,12%,35%,1)
$color-markdown-code-bg = rgba(205,217,229,0.15)

.markdown-body {
	font-size: 16px;
	line-height: 1.5;
	word-wrap: break-word;

	ul, ol {
		list-style: initial
	}
}

.markdown-body em
	font-style italic

.markdown-body kbd {
	display: inline-block;
	padding: 3px 5px;
	font: 11px "SFMono-Regular", Consolas, "Liberation Mono", Menlo, monospace;
	line-height: 10px;
	color: $color-auto-gray-7;
	vertical-align: middle;
	background-color: $color-bg-secondary;
	border: solid 1px $color-border-tertiary;
	border-bottom-color: $color-border-tertiary;
	border-radius: 6px;
	box-shadow: inset 0 -1px 0 $color-border-tertiary;
}

.markdown-body::before {
	display: table;
	content: "";
}

.markdown-body::after {
	display: table;
	clear: both;
	content: "";
}

.markdown-body > *:first-child {
	margin-top: 0 !important;
}

.markdown-body > *:last-child {
	margin-bottom: 0 !important;
}

.markdown-body a {
	background-color: initial;
	color: $blue-link
	text-decoration: none;
}

.markdown-body a:not([href]) {
	color: inherit;
	text-decoration: none;
}

.markdown-body .absent {
	color: $color-text-danger;
}

.markdown-body .anchor {
	float: left;
	padding-right: 4px;
	margin-left: -20px;
	line-height: 1;
}

.markdown-body .anchor:focus {
	outline: none;
}

.markdown-body p,
.markdown-body blockquote,
.markdown-body ul,
.markdown-body ol,
.markdown-body dl,
.markdown-body table,
.markdown-body pre,
.markdown-body details {
	margin-top: 0;
	margin-bottom: 16px;
}

.markdown-body hr {
	height: 0.25em;
	padding: 0;
	margin: 24px 0;
	background-color: $color-border-primary;
	border: 0;
}

.markdown-body blockquote {
	padding: 0 1em;
	color: $color-text-tertiary;
	border-left: 0.25em solid $color-markdown-blockquote-border;
}

.markdown-body blockquote > :first-child {
	margin-top: 0;
}

.markdown-body blockquote > :last-child {
	margin-bottom: 0;
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
	margin-top: 24px;
	margin-bottom: 16px;
	font-weight: 600;
	line-height: 1.25;
}

.markdown-body h1 .octicon-link,
.markdown-body h2 .octicon-link,
.markdown-body h3 .octicon-link,
.markdown-body h4 .octicon-link,
.markdown-body h5 .octicon-link,
.markdown-body h6 .octicon-link {
	color: $color-text-primary;
	vertical-align: middle;
	visibility: hidden;
}

.markdown-body h1:hover .anchor,
.markdown-body h2:hover .anchor,
.markdown-body h3:hover .anchor,
.markdown-body h4:hover .anchor,
.markdown-body h5:hover .anchor,
.markdown-body h6:hover .anchor {
	text-decoration: none;
}

.markdown-body h1:hover .anchor .octicon-link,
.markdown-body h2:hover .anchor .octicon-link,
.markdown-body h3:hover .anchor .octicon-link,
.markdown-body h4:hover .anchor .octicon-link,
.markdown-body h5:hover .anchor .octicon-link,
.markdown-body h6:hover .anchor .octicon-link {
	visibility: visible;
}

.markdown-body h1 tt,
.markdown-body h1 code,
.markdown-body h2 tt,
.markdown-body h2 code,
.markdown-body h3 tt,
.markdown-body h3 code,
.markdown-body h4 tt,
.markdown-body h4 code,
.markdown-body h5 tt,
.markdown-body h5 code,
.markdown-body h6 tt,
.markdown-body h6 code {
	font-size: inherit;
}

.markdown-body h1 {
	padding-bottom: 0.3em;
	font-size: 2em;
	border-bottom: 1px solid $color-border-secondary;
}

.markdown-body h2 {
	padding-bottom: 0.3em;
	font-size: 1.5em;
	border-bottom: 1px solid $color-border-secondary;
}

.markdown-body h3 {
	font-size: 1.25em;
}

.markdown-body h4 {
	font-size: 1em;
}

.markdown-body h5 {
	font-size: 0.875em;
}

.markdown-body h6 {
	font-size: 0.85em;
	color: $color-text-tertiary;
}

.markdown-body ul,
.markdown-body ol {
	padding-left: 2em;
}

.markdown-body ul.no-list,
.markdown-body ol.no-list {
	padding: 0;
	list-style-type: none;
}

.markdown-body ol[type="1"] {
	list-style-type: decimal;
}

.markdown-body ol[type="a"] {
	list-style-type: lower-alpha;
}

.markdown-body ol[type="i"] {
	list-style-type: lower-roman;
}

.markdown-body div > ol:not([type]) {
	list-style-type: decimal;
}

.markdown-body ul ul,
.markdown-body ul ol,
.markdown-body ol ol,
.markdown-body ol ul {
	margin-top: 0;
	margin-bottom: 0;
}

.markdown-body li > p {
	margin-top: 16px;
}

.markdown-body li + li {
	margin-top: 0.25em;
}

.markdown-body dl {
	padding: 0;
}

.markdown-body dl dt {
	padding: 0;
	margin-top: 16px;
	font-size: 1em;
	font-style: italic;
	font-weight: 600;
}

.markdown-body dl dd {
	padding: 0 16px;
	margin-bottom: 16px;
}

.markdown-body table {
	display: block;
	width: 100%;
	// width: max-content;
	max-width: 100%;
	overflow: auto;
}

.markdown-body table th {
	font-weight: 600;
}

.markdown-body table th,
.markdown-body table td {
	padding: 6px 13px;
	border: 1px solid $color-markdown-table-border;
}

.markdown-body table tr {
	background-color: $color-bg-primary;
	border-top: 1px solid $color-markdown-table-tr-border;
}

.markdown-body table tr:nth-child(2n) {
	background-color: $color-bg-tertiary;
}

.markdown-body table img {
	background-color: transparent;
}

.markdown-body img {
	max-width: 100%;
	box-sizing: content-box;
	background-color: $color-bg-primary;
}

.markdown-body img[align="right"] {
	padding-left: 20px;
}

.markdown-body img[align="left"] {
	padding-right: 20px;
}

.markdown-body .emoji {
	max-width: none;
	vertical-align: text-top;
	background-color: transparent;
}

.markdown-body span.frame {
	display: block;
	overflow: hidden;
}

.markdown-body span.frame > span {
	display: block;
	float: left;
	width: auto;
	padding: 7px;
	margin: 13px 0 0;
	overflow: hidden;
	border: 1px solid $color-markdown-frame-border;
}

.markdown-body span.frame span img {
	display: block;
	float: left;
}

.markdown-body span.frame span span {
	display: block;
	padding: 5px 0 0;
	clear: both;
	color: $color-text-primary;
}

.markdown-body span.align-center {
	display: block;
	overflow: hidden;
	clear: both;
}

.markdown-body span.align-center > span {
	display: block;
	margin: 13px auto 0;
	overflow: hidden;
	text-align: center;
}

.markdown-body span.align-center span img {
	margin: 0 auto;
	text-align: center;
}

.markdown-body span.align-right {
	display: block;
	overflow: hidden;
	clear: both;
}

.markdown-body span.align-right > span {
	display: block;
	margin: 13px 0 0;
	overflow: hidden;
	text-align: right;
}

.markdown-body span.align-right span img {
	margin: 0;
	text-align: right;
}

.markdown-body span.float-left {
	display: block;
	float: left;
	margin-right: 13px;
	overflow: hidden;
}

.markdown-body span.float-left span {
	margin: 13px 0 0;
}

.markdown-body span.float-right {
	display: block;
	float: right;
	margin-left: 13px;
	overflow: hidden;
}

.markdown-body span.float-right > span {
	display: block;
	margin: 13px auto 0;
	overflow: hidden;
	text-align: right;
}

.markdown-body code,
.markdown-body tt {
	padding: 0.2em 0.4em;
	margin: 0;
	font-size: 85%;
	background-color: $color-markdown-code-bg;
	border-radius: 6px;
}

.markdown-body code br,
.markdown-body tt br {
	display: none;
}

.markdown-body del code {
	text-decoration: inherit;
}

.markdown-body pre {
	word-wrap: normal;
}

.markdown-body pre > code {
	padding: 0;
	margin: 0;
	font-size: 100%;
	word-break: normal;
	white-space: pre;
	background: transparent;
	border: 0;
}

.markdown-body .highlight {
	margin-bottom: 16px;
}

.markdown-body .highlight pre {
	margin-bottom: 0;
	word-break: normal;
}

.markdown-body .highlight pre,
.markdown-body pre {
	padding: 16px;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	background-color: $color-bg-tertiary;
	border-radius: 6px;
}

.markdown-body pre code,
.markdown-body pre tt {
	display: inline;
	max-width: auto;
	padding: 0;
	margin: 0;
	overflow: visible;
	line-height: inherit;
	word-wrap: normal;
	background-color: transparent;
	border: 0;
}

.markdown-body .csv-data td,
.markdown-body .csv-data th {
	padding: 5px;
	overflow: hidden;
	font-size: 12px;
	line-height: 1;
	text-align: left;
	white-space: nowrap;
}

.markdown-body .csv-data .blob-num {
	padding: 10px 8px 9px;
	text-align: right;
	background: $color-bg-primary;
	border: 0;
}

.markdown-body .csv-data tr {
	border-top: 0;
}

.markdown-body .csv-data th {
	font-weight: 600;
	background: $color-bg-tertiary;
	border-top: 0;
}
