$current-assignments-icon-width = 1.75rem
$current-assignments-assignment-height = 1.75rem

.roster-view-new .section-current-assignments
	.role-group
		display flex

		& + .role-group
			margin-top 1rem

		.role-icon
			flex-shrink 0

			.role-icon-wrapper
				width $current-assignments-icon-width
				height @width;
				margin-top 0.15rem

				img
					display block
					width 100%
					opacity $faded-opacity

		.content
			flex 1 1 0%
			display flex
			align-items center
			margin-left 0.75rem

			.no-assignments
				color $super-faded-font
				font-size 0.875rem

			.assignments
				display flex
				margin -0.15rem -0.2rem
				flex 1 1 0%
				flex-wrap wrap

				.current-assignment
					position relative
					display flex
					align-items center
					justify-content: center
					width 6.55rem
					height $current-assignments-assignment-height
					margin 0.15rem 0.2rem
					padding-left 4px // for wow colouring
					overflow hidden
					user-select none
					border-radius: $border-radius
					border-top-left-radius 0
					border-bottom-left-radius 0
					cursor pointer
					background $focus-box-linear-gradient-selected

					&.with-remove
						width @width + 0.2
						padding-right 1.35rem

					&::before
						position absolute
						width 4px
						left 0
						top 0
						bottom 0
						display block
						content ''

					.name
						font-size 0.875rem
						overflow ellipsis

					.remove-character
						cursor pointer
						display: flex
						align-items: center
						justify-content: center
						position: absolute
						top 0
						bottom 0
						right 0
						width 1.35rem
						background alpha($primary, 35%)
						font-size 0

						&:hover
							background $primary

						i
							font-size 0.875rem
							color $red + 20%
							padding-bottom 2px
