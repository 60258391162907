.roster-view-new .boss-cooldown-view .cooldown-view-content .cooldown-sheet-table .col-actions .action
	display: flex
	border 1px solid $light-100
	border-radius $border-radius
	padding 0.15rem 0.75rem 0.35rem
	align-items center
	background: $light-50

	&.editable
		padding-right 0.15rem

		.action-field
			cursor pointer

	.actions-menu-button
		width 2rem
		height @width
		font-size 1.5rem
		color $light-400
		margin-left 0.25rem

		&:hover
			color $light-600

	.action-field-wrapper
		position: relative

		& + .action-field-wrapper
			margin-left 0.65rem

		.action-field-select
			top 100%

	.action-field
		border-bottom 1px solid $light-400
		display flex
		justify-content: space-between
		align-items: center

		&:hover
			border-color $light-500

			.dropdown-icon
				color $light-600

			.action-field-text
				&.placeholder
					color $light-500

		.dropdown-icon
			color $light-400
			margin-left 0.25rem
			position: relative
			top 2px

	.action-field-select
		left: -3rem

		.react-select-prefix__option
			display: flex
			align-items: center

		.action-select-option-image
			width 1.6rem
			height @width
			border-radius: $border-radius
			margin-right 0.5rem

	.action-field-text
		padding 0.25rem

		&.submitting
			opacity 0.7

		&.placeholder
			color $light-400

	.action-field-icon
		width 1.25rem
		height @width
		margin-right 0.15rem
