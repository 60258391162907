.droptimizer-list-view
	padding 8vh 2rem 2rem

	.heading
		text-align center
		width 30rem
		max-width 95%
		margin 0 auto 3rem

		h1
			$super-bold()

	.content
		margin 1rem auto 0
		width 35rem
		max-width 95%

	.report
		display flex
		flex-direction row
		justify-content space-between
		align-items center
		padding 1em 1.25rem
		text-align left
		border 1px solid $card-border
		border-radius $border-radius
		background-color $foreground

		& + .report
			margin-top 0.75rem

		.report-info
			text-align: right

			.report-info-top
				display: flex
				align-items: center
				justify-content: end

				.button
					margin-left 0.5rem

			.raidbots-link
				$linkify()

			.report-age
				margin-top 0.15rem
				color $faded-font
