.roster-view-new
	width 100%
	max-width 90rem
	margin 0 auto
	padding 2rem 2rem 20rem

	.page-content
		margin-top: 1.5rem

	.roster-redesign-help
		margin-top 0.5rem
		border-radius $small-border-radius
		background-color $foreground-light
		padding 1rem 1.5rem
		border-inline-start-width 4px
		border-inline-start-color $primary
		border-style solid

		p
			&:last-child
				margin-bottom 0

	.roster-redesign-help
		position: relative

		.close-icon
			display: flex
			align-items: center
			justify-content: center
			position: absolute
			border-radius $border-radius
			top 0.5rem
			right 0.5rem
			padding 0.5rem
			cursor pointer

			&:hover
				background-color $light-100
