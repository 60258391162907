.settings-page .settings-content.external-characters-settings
	.character-row-wrapper
		&.is-missing
			.settings-character-row
				background $error-box-linear-gradient

			& + .character-row-wrapper
				margin-top 2rem

		.character-is-missing
			display flex
			align-items center
			color $red

			.info-icon
				margin-left 0.5rem

	.settings-character-row

		.right
			display flex

			.location-details
				text-align right
				margin-right 1rem

				.guild-name
					$bold()
					font-size 1.15rem

				.realm
					color $secondary-font

			.overflow-menu
				position relative
				display flex

				i
					font-size 1.5rem
					padding 0.5rem
					cursor pointer
					transform rotate(90deg)

				.menu-widget
					top 100%
					right -3rem
