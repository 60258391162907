@keyframes add-cooldown-action-icon-spin
	0%
		transform rotate(0deg)

	100%
		transform rotate(359deg)

.roster-view-new .boss-cooldown-view .cooldown-view-content .cooldown-sheet-table .col.col-actions
	.add-action-container
		.dormant
			display flex
			color $light-300
			cursor pointer
			padding 0.35rem
			font-size 1.35rem

			&:hover
				color $light-600

		.actioning
			display: flex
			color $light-400
			animation add-cooldown-action-icon-spin 2s infinite linear
			padding 0.35rem
			font-size 1.35rem

	.action-items
		flex 1 1 auto
		display flex
		flex-wrap wrap
		gap 0.65rem 0.75rem
		align-items: center
